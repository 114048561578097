import React, { useEffect, useRef, useState } from "react";
import Styles from "./SpaceShip.module.sass";
import cn from "classnames";
import { Col, Row } from "antd";
import BreedfiAd from "../../components/Advertisement/BreedfiAd";
import { SPACE_GAME_URL } from "../../Config/config";
import { Link, useParams } from "react-router-dom";
import "./index.css";
import { spaceshipGame } from "../../services/ApiServices";
import LeaderBoard from "./LeaderBoard";
const SpaceshipGame = (props) => {
  const { gameId, status } = useParams();
  const [page, setPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  const [leaderboardPage, setLeaderboardPage] = useState(1);
  const [leaderboardTotalDocs, setLeaderboardTotalDocs] = useState(0);
  const [alltimeLeaderboardPage, setAlltimeLeaderboardPage] = useState(1);
  const [alltimeLeaderboardTotalDocs, setAlltimeLeaderboardTotalDocs] =
    useState(0);

  const [leaderboardLoading, setLeaderboardLoading] = useState(false);
  const [alltimeLeaderboardLoading, setAlltimeLeaderboardLoading] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [leaderboard, setLeaderboard] = useState([]);
  const [alltimeLeaderboard, setAlltimeLeaderboard] = useState([]);
  useEffect(() => {
    getLeaderbord();
  }, [page]);
  useEffect(() => {
    getAlltimeLeaderbord();
  }, [leaderboardPage]);
  const getLeaderbord = async () => {
    setLeaderboardLoading(true);
    let result;
    try {
      result = await spaceshipGame(true);
    } catch (e) {
      console.log(e);
      setLeaderboardLoading(false);
    }
    try {
      if (result.success) {
        setLeaderboardLoading(false);
        setLeaderboard(result.result);
        setLeaderboardTotalDocs(result.result.length);
      }
    } catch (e) {
      console.log(e);
      setLeaderboardLoading(false);
    }
  };
  const getAlltimeLeaderbord = async () => {
    setAlltimeLeaderboardLoading(true);
    let result;
    try {
      result = await spaceshipGame(false);
    } catch (e) {
      console.log(e);
      setAlltimeLeaderboardLoading(false);
    }
    try {
      if (result.success) {
        setAlltimeLeaderboardLoading(false);
        setAlltimeLeaderboard(result.result);
        setAlltimeLeaderboardTotalDocs(result.result.length);
      }
    } catch (e) {
      console.log(e);
      setAlltimeLeaderboardLoading(false);
    }
  };
  return (
    <div className={cn("container")}>
      <iframe
        className={Styles.gamewindow}
        allowFullScreen={true}
        noresize="noresize"
        src={SPACE_GAME_URL}
        id="iframe"
      ></iframe>
      <LeaderBoard
          leaderboard={leaderboard}
          leaderboardLoading={leaderboardLoading}
          leaderboardPage={leaderboardPage}
          leaderboardTotalDocs={leaderboardTotalDocs}
          setLeaderboardPage={(page) => {
            setLeaderboardPage(page);
          }}
          alltimeLeaderboard={alltimeLeaderboard}
          alltimeLeaderboardLoading={alltimeLeaderboardLoading}
          alltimeLeaderboardPage={alltimeLeaderboardPage}
          alltimeLeaderboardTotalDocs={alltimeLeaderboardTotalDocs}
          setAlltimeLeaderboardPage={(page) => {
            setAlltimeLeaderboardPage(page);
          }}
        />
    </div>
  );
};
export default SpaceshipGame;
