import React, { useEffect } from "react";
import styles from './BreedfiAd.module.sass'
const BreedfiAd = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//servedby.revive-adserver.net/asyncjs.php";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const randomNumber = Math.random();
  const zoneId = 13193;
  return (
    <div>
      <iframe
        id="acd42453"
        name="acd42453"
        src={`https://servedby.revive-adserver.net/afr.php?zoneid=${zoneId}&cb=${randomNumber}`}
        frameborder="0"
        scrolling="no"
        width="300"
        height="415"
        title="Advertisement"
        allow="autoplay"
        className={styles.ad}
      >
        <a
          href={`https://servedby.revive-adserver.net/ck.php?n=a911af10&cb=${randomNumber}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={`https://servedby.revive-adserver.net/avw.php?zoneid=${zoneId}&cb=${randomNumber}&n=a911af10`}
            border="0"
            alt=""
          />
        </a>
      </iframe>
      <br />
      {/* Revive Adserver Hosted edition iFrame Tag - Generated with Revive Adserver v5.4.1  */}
      <div>
        <iframe
          id="a042b38f"
          name="a042b38f"
          src="https://servedby.revive-adserver.net/afr.php?zoneid=13388&amp;cb=INSERT_RANDOM_NUMBER_HERE"
          frameborder="0"
          scrolling="no"
          width="300"
          height="250"
          allow="autoplay"
        className={styles.ad}

        >
          <a
            href="https://servedby.revive-adserver.net/ck.php?n=a72c56e1&amp;cb=INSERT_RANDOM_NUMBER_HERE"
            target="_blank"
          >
            <img
              src="https://servedby.revive-adserver.net/avw.php?zoneid=13388&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a72c56e1"
              border="0"
              alt=""
            />
          </a>
        </iframe>
      </div>
    </div>
  );
};

export default BreedfiAd;
