import React, { useEffect, useState } from "react";
import Styles from "../Challenges.module.sass";
import { Row, Col, Empty } from "antd";
import HistoryShade from "../../../../images/historyshade.png";
import {
  GetKibblezHistory,
  GetUserAssets,
} from "../../../../services/ApiServices";
import cn from "classnames";
import moment from "moment";
import Loader from "../../../../components/Loader";
import Kibblez from "../../../../images/kibblez.png";
import Bonez from "../../../../images/bonez.png";
import CounDown from "../../../../components/CountDown";
import { minifyAddress } from "../../../../utils/helpers";
import { Link } from "react-router-dom";

const time = [
  { label: "All time", value: 5 },
  { label: "4 hours", value: 1 },
  { label: "12 hours", value: 2 },
  { label: "24 hours", value: 3 },
  { label: "Weekly", value: 4 },
];
const CurrentChallenge = ({
  currentChallenge,
  getLeaderboard,
  isCurrentChallengeLoading,
  countdown4hr,

}) => {
  const [selectedIdex, setSelectedIdex] = useState(0);
  return (
    <>
      <div className={Styles.menu}>
        <Row>
          <Col md={4} sm={24} xs={24}>
            <div className={Styles.seller}>View my ranking</div>
          </Col>
          <Col md={12} sm={24} xs={24}>
            <div>
              {time.map((data, index) => (
                <div
                  key={index}
                  className={
                    index === selectedIdex ? "leader-active" : "leader"
                  }
                  onClick={() => {
                    setSelectedIdex(index);
                    getLeaderboard(data.value);
                  }}
                >
                  <div className="leader-text">{data.label}</div>
                </div>
              ))}
            </div>
          </Col>
          <Col md={8} sm={24} xs={24}>
            <div className={Styles.countdown}>
              Ends in <CounDown asset={countdown4hr} fromHour={true} />
            </div>
          </Col>
        </Row>
      </div>
      <div className={Styles.history_table}>
        <div className={Styles.rowdata}>
          <Row>
            <Col span={3}>
              <div className={Styles.seller}>Position</div>
            </Col>
            <Col span={3}>
              <div className={Styles.seller}>Name</div>
            </Col>
            <Col span={3}>
              <div className={Styles.seller}>Address</div>
            </Col>
            <Col span={3}>
              <div className={Styles.seller}>Duration</div>
            </Col>
            <Col span={4}>
              <div className={Styles.seller}>Matches played</div>
            </Col>
            <Col span={4}>
              <div className={Styles.seller}>Total time spent</div>
            </Col>
            <Col span={4}>
              <div className={Styles.seller}>To win</div>
            </Col>
          </Row>
        </div>
        {/* <img src={HistoryShade} className={Styles.history_shade} /> */}
        {isCurrentChallengeLoading ? (
          <Loader className={Styles.loader} />
        ) : currentChallenge.length ? (
          <>
            {currentChallenge.map((data, index) => {
              return (
                <div
                  className={Styles.rowdata}
                  key={index}
                >
                  <Row>
                    <Col span={3}>
                      <div className={Styles.pricefield}>{index + 1}</div>
                    </Col>
                    <Col span={3}>
                      <div className={Styles.pricefield}>{data.userName}</div>
                    </Col>
                    <Col span={3}>
                      {data.address ? <div className={Styles.hash}>
                        <Link to={"/account/" + data.address}>
                          {data.address ? minifyAddress(data.address, 6) : "-"}
                        </Link>
                      </div> :
                        <div className={Styles.pricefield}>-</div>
                      }
                    </Col>
                    <Col span={3}>
                      <div className={Styles.status}>{data.leastDuration.toFixed(2)}</div>
                    </Col>
                    <Col span={4}>
                      <div className={Styles.status}>{data.totalMatches}</div>

                    </Col>
                    <Col span={4}>
                      <div className={Styles.pricefield}>
                        {moment.utc(data.totalDuration * 1000).hours()}
                        hrs&nbsp;
                        {moment.utc(data.totalDuration * 1000).minutes()}mins&nbsp;
                        {moment.utc(data.totalDuration * 1000).seconds()}secs
                      </div>
                    </Col>

                    <Col span={4}>
                      {data.toWin ? (
                        <div className={Styles.status}>
                          {data.toWin}
                          <img className={Styles.icon} src={Bonez} />
                        </div>
                      ) : (
                        <div className={Styles.pricefield}>NA</div>
                      )}
                    </Col>
                  </Row>
                </div>
              );
            })}
          </>
        ) : (
          <p className={Styles.empty}>No data available</p>
        )}
      </div>
    </>
  );
};
export default CurrentChallenge;
