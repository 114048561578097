import React, { useEffect, useRef, useState } from "react";
import Styles from "./LiveRace.module.sass";
import cn from "classnames";
import { Col, Row } from "antd";
import BreedfiAd from "../../components/Advertisement/BreedfiAd";
import { RACE_URL,RACE_REPLAY_URL } from "../../Config/config";
import { Link, useParams } from "react-router-dom";

const LiveRace = (props) => {
  const { gameId,status } = useParams();

  return (
    <div className={cn("container")}>
      <Row>
        <Col md={18} lg={18} xs={24} sm={24}>
          <iframe 
            className={Styles.gamewindow}
            src={status=="live"?RACE_URL+gameId:RACE_REPLAY_URL+gameId}
          ></iframe>
        </Col>
        <Col md={6} lg={6} xs={24} sm={24}>
          <div className={Styles.advertisement}>

          <BreedfiAd />
          
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default LiveRace;
