import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./Drawer.module.sass";
import { TfiLock } from "react-icons/tfi";
import { VscHome } from "react-icons/vsc";
import { HiOutlinePlay, HiOutlineShoppingBag } from "react-icons/hi";
import { BsGenderAmbiguous, BsQuestionCircle } from "react-icons/bs";
import { Divider } from "antd";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { RiFileList2Line } from "react-icons/ri";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { AiOutlinePlayCircle } from "react-icons/ai";

const DrawerContent = (props, { className }) => {
  const [visible, setVisible] = useState(false);
  const [submenu, setSubmenu] = useState();
  const [pathNum, setPathNum] = useState(0);
  const menu = [
    { name: "HOME", icon: <VscHome className={styles.icon} />, url: "/" },
    {
      name: "MARKETPLACE",
      url: "/marketplace",
      icon: <HiOutlineShoppingBag className={styles.icon} />,
    },
    { name: "GAME", icon: <HiOutlinePlay className={styles.icon} /> },
    {
      name: "BREEDING",
      url: "",
      icon: <BsGenderAmbiguous className={styles.icon} />,
      subHead: [
        { title: "BREEDING", url: "/breeding" },
        { title: "MASS BREEDING", url: "/breeding_g1" },
      ],
      upIcon: <MdKeyboardArrowUp className={styles.icon} />,
      downIcon: <MdKeyboardArrowDown className={styles.icon} />,
    },
    {
      name: "GAMES",
      url: "",
      icon: <AiOutlinePlayCircle className={styles.icon} />,
      subHead: [
        { title: "THE DOG TRACK", url: "/spaceship" },
        { title: "SPACESHIP SHOOTER", url: "/spaceshooter" },
        { title: "RACEZ", url: "/race" },
        // { title: "3D Space Battle", url: "/spaceshipGame" },
        // { title: "SPIN WHEEL", url: "/spin" },
      ],
      upIcon: <MdKeyboardArrowUp className={styles.icon} />,
      downIcon: <MdKeyboardArrowDown className={styles.icon} />,
    },
  ];
  const commonMenu = [
    {
      name: "FAQ",
      icon: <BsQuestionCircle className={styles.icon} />,
      href: "/faq",
    },
    {
      name: "DISCLAIMER",
      icon: <IoIosInformationCircleOutline className={styles.icon} />,
      href: "/Disclaimer",
    },

    {
      name: "SECURITY & PRIVACY POLICY",
      icon: <TfiLock className={styles.icon} />,
      href: "/Privacypolicy",
    },
    {
      name: "TERMS OF USE",
      icon: <RiFileList2Line className={styles.icon} />,
      href: "/Terms",
    },
  ];
  useEffect(() => {
    if (props.history && !visible) {
      let urlName = props?.history?.location?.pathname.split("/");
      console.log(urlName);
      switch (urlName[1]) {
        case "":
          setPathNum(0);
          break;
        case "spaceship":
        case "spaceshooter":
        case "race":
        case "live-race":
        case "spaceshipGame":
          setPathNum(2);
          break;
        case "marketplace":
          setPathNum(1);
          break;
        case "breeding":
        case "breeding_g1":
          setPathNum(3);
          break;
        default:
          setPathNum(1000);
          break;
      }
    }
  });
  return (
    <div>
      {menu.map((data, index) =>
        data.url ? (
          <div key={index}>
            <Link
              className={pathNum === index ? styles.activeMenu : styles.menu}
              to={data.url}
              onClick={() => {
                setPathNum(index);
                props.onClose();
              }}
            >
              <div>{data.icon}</div>
              <div> {data.name}</div>
            </Link>
          </div>
        ) : data.subHead && data.subHead.length ? (
          <div key={index}>
            <button
              className={styles.menu1}
              onClick={() => {
                setVisible(true);
                setSubmenu(data.subHead);
                setVisible(!visible);
                setPathNum(index);
              }}
            >
              <div
                className={pathNum === index ? styles.activeMenu : styles.menu}
              >
                {" "}
                {data.icon} <div>{data.name}</div>
              </div>
              {visible && pathNum === index ? (
                <>{data.downIcon}</>
              ) : (
                <>{data.upIcon}</>
              )}
            </button>
            {visible && pathNum === index && (
              <div className={styles.body}>
                {submenu.map((x, index) => (
                  <div key={index} className={styles.subMenu}>
                    <Link
                      className={
                        pathNum === index
                          ? styles.activeSubMenu
                          : styles.subMenu
                      }
                      to={x.url}
                      onClick={() => {
                        setVisible(!visible);
                        setPathNum(index);
                        props.onClose();
                      }}
                    >
                      {x.title}
                    </Link>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : null
      )}
      <Divider className={styles.divider} />
      {commonMenu.map((data, index) => (
        <Link
          to={data.href}
          onClick={() => {
            props.onClose();
          }}
        >
          <div className={styles.menu}>
            {data.icon} {data.name}
          </div>
        </Link>
      ))}
    </div>
  );
};

export default DrawerContent;
