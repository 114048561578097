import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Link, useParams } from "react-router-dom";
import styles from "./Challenges.module.sass";
// import Icon from "../../components/Icon";
import CurrentChallenge from "./CurrentChallenge";



const Challenges = ({currentChallenge,getLeaderboard,isCurrentChallengeLoading,previousChallenge,isPreviousChallengeLoading,get24hrLeaderboard ,countdown4hr,countdown24hr}) => {

  const [activeIndex, setActiveIndex] = useState(0);


  return (

    <div className={cn("container", styles.container)}>
      <p className={styles.title}>
        <span className={styles.heading}>LEADERBOARD </span>
      </p>


      {/* <div className={styles.nav}>
        {navLinks.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            key={index}
            onClick={() => setActiveIndex(index)}
          >
            {x}
          </button>
        ))}
      </div> */}
      <div className={styles.group}>
        <div className={styles.item}>
          {activeIndex === 0 && (<>
            <CurrentChallenge getLeaderboard={getLeaderboard} currentChallenge={currentChallenge} isCurrentChallengeLoading={isCurrentChallengeLoading} countdown4hr={countdown4hr} />
          </>
          )}
          
        </div>
      </div>
    </div>
  );
};

export default Challenges;
