import React, { useEffect, useRef, useState } from "react";
import Styles from "./Game.module.sass";
import { Row, Col } from "antd";
import {
  GameLeaderBoard,
  GameTimeLapseLeaderBoard,
  GetPlayableAssets,
} from "../../services/ApiServices";
import cn from "classnames";
import moment from "moment";
import Modal from "../../components/NewModal";
import ChallengeModal from "./ChallengeModal";
import { useSnackbar } from "notistack";
import { useWeb3React } from "@web3-react/core";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import Challenges from "./Challenges";
import BlackModal from "../../components/BlackModal";
import { ASSET_ADDRESS } from "../../Config/config";

const Game = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isChallengeModal, setIsChallengeModal] = useState(false);
  const [assets, setAssets] = useState([]);
  const [asset, setAsset] = useState({});
  const { account, active, activate, error, deactivate } = useWeb3React();
  const [isGameShow, setIsGameShow] = useState(false);
  const [url, setURL] = useState("");
  const [currentChallenge, setCurrentChallenge] = useState([]);
  const [isCurrentChallengeLoading, setIsCurrentChallengeLoading] =
    useState(false);
  const [previousChallenge, setPreviousChallenge] = useState([]);
  const [isPreviousChallengeLoading, setIsPreviousChallengeLoading] =
    useState(false);
  const navigate = useNavigate();
  const [countdown4hr, setCountdown4hr] = useState();
  const [countdown24hr, setCountdown24hr] = useState(
    moment.utc("24:00", "HH:mm").valueOf()
  );

  useEffect(() => {
    if (account) getAssets(account);
  }, [account]);
  useEffect(() => {
    getLeaderboard(5);
    get24hrLeaderboard(5);
  }, []);
  const getLeaderboard = async (time) => {
    setIsCurrentChallengeLoading(true);
    let result;
    try {
      result = await GameTimeLapseLeaderBoard(time, "&limit=20");
    } catch (e) {
      console.log(e);
      setIsCurrentChallengeLoading(false);
    }
    try {
      if (result.success) {
        setIsCurrentChallengeLoading(false);
        setCurrentChallenge(result.result);
      }
    } catch (e) {
      setIsCurrentChallengeLoading(false);
      console.log(e);
    }
  };
  const get24hrLeaderboard = async (time) => {
    setIsPreviousChallengeLoading(true);
    let result;
    try {
      result = await GameLeaderBoard(time, "24HR");
    } catch (e) {
      console.log(e);
      setIsPreviousChallengeLoading(false);
    }
    try {
      if (result.success) {
        setIsPreviousChallengeLoading(false);
        setPreviousChallenge(result.result);
      }
    } catch (e) {
      setIsPreviousChallengeLoading(false);
      console.log(e);
    }
  };
  const getAssets = async () => {
    setIsLoading(true);
    let result;
    try {
      result = await GetPlayableAssets(ASSET_ADDRESS);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
    try {
      if (result.success) {
        setIsLoading(false);
        setAssets(result.result);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const GameShow = (tokenId, userName, token) => {
    setIsChallengeModal(false);
    setIsGameShow(true);
    setURL(
      "https://timelapse.cyberdogz.io/?tokenId=" +
      tokenId +
      "&userName=" +
      userName +
      "&token=" +
      token
    );
  };
  const interval = useRef();
  useEffect(() => {
    interval.current = setInterval(() => {
      const currentDate = moment.utc().valueOf();
      for (var i = 1; i <= 4; i++) {
        if ((moment.utc(currentDate).hour() + i) % 4 === 0) {
          let date = moment.utc().startOf("hour");
          setCountdown4hr(moment.utc(date).add(i, "hour").utc().valueOf());
        }
      }
    }, 1000);

    // move clean up function to here:
    return () => {
      clearInterval(interval.current);
      interval.current = null;
    };
  }, [countdown4hr]);
  useEffect(() => {
    interval.current = setInterval(() => {
      const currentDate = moment.utc().valueOf();
      if (countdown24hr - currentDate < 0) {
        setCountdown24hr(countdown24hr + 86400000);
      }
    }, 1000);

    // move clean up function to here:
    return () => {
      clearInterval(interval.current);
      interval.current = null;
    };
  }, [countdown24hr]);
  return (
    <div className={cn("container")}>
      {!isGameShow ? (
        <>
          <div className={Styles.containerBg}>
            {/* <p className={Styles.head}>
            <span className={Styles.yield}> CYBERGAMEZ</span> THE DOG TRACK
          </p> */}
            <div className={Styles.container}>
              <p className={Styles.head}>The Dog Track</p>
              <div className={Styles.subhead}>
                Select your CyberDogz to join ongoing race
              </div>
              {isLoading ? (
                <Loader className={Styles.loader} />
              ) : assets.length ? (
                <Row>
                  {assets.map((x, index) => (
                    <Col md={6} xs={24} sm={12} key={index}>
                      <div className={Styles.Card}>
                        <div
                          className={Styles.card}
                          onClick={() => {
                            setIsChallengeModal(true);
                            setAsset(x);
                          }}
                        >
                          <Row>
                            <Col span={24}>
                              <div className={Styles.preview}>
                                <img
                                  src={x.image}
                                  className={Styles.previewImg}
                                  alt="bg"
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className={Styles.lineBorder}></div>
                        <div className={Styles.cards}>
                          <div className={Styles.cardsTxt}>
                            <div className={Styles.title}>
                              {x.name.split(":")[0].toUpperCase()}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              ) : (
                <p className={Styles.empty}>No asset available</p>
              )}
            </div>
          </div>
          <Challenges
            getLeaderboard={getLeaderboard}
            currentChallenge={currentChallenge}
            isCurrentChallengeLoading={isCurrentChallengeLoading}
            previousChallenge={previousChallenge}
            isPreviousChallengeLoading={isPreviousChallengeLoading}
            get24hrLeaderboard={get24hrLeaderboard}
            countdown4hr={countdown4hr}
            countdown24hr={countdown24hr}
          />
          <BlackModal
            visible={isChallengeModal}
            outerClassName={Styles.modal}
            onClose={() => setIsChallengeModal(false)}
          >
            <ChallengeModal
              asset={asset}
              GameShow={GameShow}
              countdown4hr={countdown4hr}
              countdown24hr={countdown24hr}
            />
          </BlackModal>
        </>
      ) : (
        <>
          <iframe title="url" className={Styles.gamewindow} src={url}></iframe>
        </>
      )}
    </div>
  );
};
export default Game;
