import React, { useState } from "react";
import styles from "./PrivacyPolicy.module.sass";
import { Col, Row, Table } from "antd";
import cn from "classnames";
import Pagination from "../../components/Pagenation";

const Privacypolicy = () => {
  const[totalDocs, setTotalDocs] = useState(11)
  const[page, setPage] = useState(1)

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      align: "left",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "	Examples",
      className: "column-examples",
      dataIndex: "examples",
      align: "left",
    },
    {
      title: "Collected",
      dataIndex: "collected",
      align: "center",
    },
  ];

  const data = [
    {
      key: "1",
      category: "A.Identifiers",
      examples:
        "Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address and account name",
      collected: "YES",
    },
    {
      key: "2",
      category:
        "B.Personal information categories listed in the California Customer Records statute",
      examples:
        "Name, contact information, education, employment, employment history and financial information",
      collected: "YES",
    },
    {
      key: "3",
      category:
        "C.Protected classification characteristics under California or federal law",
      examples: "Gender and date of birth",
      collected: "NO",
    },
    {
      key: "4",
      category: "D.Commercial information",
      examples:
        "Transaction information, purchase history, financial details and payment information",
      collected: "NO",
    },
    {
      key: "5",
      category: "E.Biometric information",
      examples: "	Fingerprints and voiceprints",
      Collected: "NO",
    },
    {
      key: "6",
      category: "F.Internet or other similar network activity",
      examples:
        "Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems and advertisements",
      collected: "YES",
    },

    {
      key: "7",
      category: "G.Geolocation data",
      examples: "Device location",
      collected: "YES",
    },
    {
      key: "8",
      category:
        "H.Audio, electronic, visual, thermal, olfactory, or similar information",
      examples:
        "	Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems and advertisements",
      collected: "NO",
    },
    {
      key: "9",
      category: "I. Professional or employment-related information",
      examples:
        "	Business contact details in order to provide you our services at a business level, job title as well as work history and professional qualifications if you apply for a job with us",
      collected: "NO",
    },
    {
      key: "10",
      category: "J. Education Information",
      examples: "	Student records and directory information",
      collected: "NO",
    },
    {
      key: "11",
      category: "K. Inferences drawn from other personal information",
      examples:
        "	Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics",
      collected: "NO",
    },
  ];

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <h3 className={cn("h3", styles.title)}>Privacy policy</h3>
        <div className={styles.privacyBorder}>
          <div className={styles.store}>PRIVACY NOTICE</div>
          <div className={styles.grop}>
            <p>
              Thank you for choosing to be part of our community at CYBERDOGZ
              Decentralized Autonomous Organization (DAO), Affiliates and
              Community, doing business as CYBERDOGZ{" "}
              <span style={{ color: "#ffffff", Text: "bold" }}>
                ("CYBERDOGZ," "we," "us," or "our").
              </span>{" "}
              We are committed to protecting your personal information and your
              right to privacy. If you have any questions or concerns about this
              privacy notice or our practices with regard to your personal
              information, please contact us at{" "}
              <a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=hello@CYBERDOGZ.io">
                {" "}
                hello@cyberdogz.io.
              </a>
              <br /> <br />
              This privacy notice describes how we might use your information if
              you:
              <br />▪ Visit our website at{" "}
              <a href="https://www.cyberdogz.io">https://www.cyberdogz.io </a>
              <br />
              ▪ Engage with us in other related ways ― including any sales,
              marketing, or events
              <br />
              In this privacy notice, if we refer to:
              <br />
            </p>
            <p>
              {" "}
              <span style={{ color: "#ffffff", Text: "bold" }}>
                {" "}
                " ▪ Website,"{" "}
              </span>{" "}
              we are referring to any website of ours that references or links
              to this policy
            </p>

            <p>
              {" "}
              <span style={{ color: "#ffffff", Text: "bold" }}>
                {" "}
                " ▪ Services,"{" "}
              </span>
              we are referring to our Website, and other related services,
              including any sales, marketing, or events
            </p>
            <br />
            <p>
              The purpose of this privacy notice is to explain to you in the
              clearest way possible what information we collect, how we use it,
              and what rights you have in relation to it. If there are any terms
              in this privacy notice that you do not agree with, please
              discontinue use of our Services immediately.
              <br />
              <u>
                {" "}
                Please read this privacy notice carefully, as it will help you
                understand what we do with the information that we collect.
              </u>
            </p>
          </div>
          <div className={styles.store}>1. WHAT INFORMATION DO WE COLLECT?</div>
          <div className={styles.grop} style={{ bold: "black" }}>
            <span style={{ color: "#ffffff", Text: "bold" }}>
              Personal information you disclose to us
            </span>
            <br />
            In Short: We collect personal information that you provide to us.
            <p>
              We collect personal information that you voluntarily provide to us
              when you register on the Website, express an interest in obtaining
              information about us or our products and Services, when you
              participate in activities on the Website or otherwise when you
              contact us.
              <br />
              The personal information that we collect depends on the context of
              your interactions with us and the Website, the choices you make
              and the products and features you use. The personal information we
              collect may include the following:
              <br />
              <b>Personal Information Provided by You.</b> We collect email
              addresses; metamask eth addresses; solana addresses; cyberdogz
              wallet addresses; and other similar information.
              <br />
              <span style={{ color: "#ffffff", Text: "bold" }}>
                Payment Data.
              </span>
              We may collect data necessary to process your payment if you make
              purchases, such as your payment instrument number (such as a
              credit card number), and the security code associated with your
              payment instrument. All payment data is stored by Metamask. You
              may find their privacy notice link(s) here:
              https://consensys.net/privacy-policy/.
            </p>
            All personal information that you provide to us must be true,
            complete and accurate, and you must notify us of any changes to such
            personal information.
            <br />
          </div>
          <div className={styles.store}>
            Information automatically collected
          </div>
          <div className={styles.grop}>
            <p>
              <span style={{ color: "#ffffff", Text: "bold" }}>In Short: </span>{" "}
              Some information — such as your Internet Protocol (IP) address
              and/or browser and device characteristics — is collected
              automatically when you visit our Website.{" "}
            </p>
            <p>
              We automatically collect certain information when you visit, use
              or navigate the Website. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Website and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Website, and for our
              internal analytics and reporting purposes.
            </p>
            <p>
              Like many businesses, we also collect information through cookies
              and similar technologies.
              <br />
              The information we collect includes:{" "}
            </p>
            <br />
            <p>
              {" "}
              ▪ Log and Usage Data. Log and usage data is service-related,
              diagnostic, usage and performance information our servers
              automatically collect when you access or use our Website and which
              we record in log files. Depending on how you interact with us,
              this log data may include your IP address, device information,
              browser type and settings and information about your activity in
              the Website (such as the date/time stamps associated with your
              usage, pages and files viewed, searches and other actions you take
              such as which features you use), device event information (such as
              system activity, error reports (sometimes called 'crash dumps')
              and hardware settings).
            </p>
            <p>
              ▪ Device Data. We collect device data such as information about
              your computer, phone, tablet or other device you use to access the
              Website. Depending on the device used, this device data may
              include information such as your IP address (or proxy server),
              device and application identification numbers, location, browser
              type, hardware model Internet service provider and/or mobile
              carrier, operating system and system configuration information.
            </p>
            <p>
              ▪ Location Data. We collect location data such as information
              about your device's location, which can be either precise or
              imprecise. How much information we collect depends on the type and
              settings of the device you use to access the Website. For example,
              we may use GPS and other technologies to collect geolocation data
              that tells us your current location (based on your IP address).
              You can opt out of allowing us to collect this information either
              by refusing access to the information or by disabling your
              Location setting on your device. Note however, if you choose to
              opt out, you may not be able to use certain aspects of the
              Services.
            </p>
          </div>
          <div className={styles.store}>
            {" "}
            2. HOW DO WE USE YOUR INFORMATION?
          </div>
          <div className={styles.grop}>
            <p>
              {" "}
              <span style={{ color: "#ffffff", Text: "bold" }}>
                In Short:{" "}
              </span>{" "}
              We process your information for purposes based on legitimate
              business interests, the fulfillment of our contract with you,
              compliance with our legal obligations, and/or your consent.
            </p>
            <p>
              We use personal information collected via our Website for a
              variety of business purposes described below. We process your
              personal information for these purposes in reliance on our
              legitimate business interests, in order to enter into or perform a
              contract with you, with your consent, and/or for compliance with
              our legal obligations. We indicate the specific processing grounds
              we rely on next to each purpose listed below.
            </p>
          </div>
          <div className={styles.store}>
            We use the information we collect or receive:{" "}
          </div>
          <div className={styles.grop}>
            <p>
              <span style={{ color: "#ffffff", Text: "bold" }}>
                {" "}
                ▪ To facilitate account creation and logon process.{" "}
              </span>
              If you choose to link your account with us to a third-party
              account (such as your Google or Facebook account), we use the
              information you allowed us to collect from those third parties to
              facilitate account creation and logon process for the performance
              of the contract.
            </p>
            <p>
              <span style={{ color: "#ffffff", Text: "bold" }}>
                ▪ To post testimonials.{" "}
              </span>
              We post testimonials on our Website that may contain personal
              information. Prior to posting a testimonial, we will obtain your
              consent to use your name and the content of the testimonial. If
              you wish to update, or delete your testimonial, please contact us
              at hello@cyberdogz.io and be sure to include your name,
              testimonial location, and contact information.
            </p>
            <p>
              <span style={{ color: "#ffffff", Text: "bold" }}>
                ▪ Request feedback.{" "}
              </span>
              We may use your information to request feedback and to contact you
              about your use of our Website.
            </p>
            <p>
              <span style={{ color: "#ffffff", Text: "bold" }}>
                ▪ To enable user-to-user communications.{" "}
              </span>
              We may use your information in order to enable user-to-user
              communications with each user's consent.
            </p>
            <p>
              <span style={{ color: "#ffffff", Text: "bold" }}>
                ▪ To manage user accounts.
              </span>
              We may use your information for the purposes of managing our
              account and keeping it in working order.
            </p>
            <p>
              <span style={{ color: "#ffffff", Text: "bold" }}>
                ▪ To send administrative information to you.{" "}
              </span>
              We may use your personal information to send you product, service
              and new feature information and/or information about changes to
              our terms, conditions, and policies.
            </p>
            <p>
              <span style={{ color: "#ffffff", Text: "bold" }}>
                ▪ To protect our Services.
              </span>{" "}
              We may use your information as part of our efforts to keep our
              Website safe and secure (for example, for fraud monitoring and
              prevention).
            </p>
            <p>
              <span style={{ color: "#ffffff", Text: "bold" }}>
                ▪ To enforce our terms, conditions and policies for business
                purposes, to comply with legal and regulatory requirements or in
                connection with our contract.
              </span>
            </p>
            <p>
              <span style={{ color: "#ffffff", Text: "bold" }}>
                ▪ To respond to legal requests and prevent harm.{" "}
              </span>
              If we receive a subpoena or other legal request, we may need to
              inspect the data we hold to determine how to respond.
            </p>
            <p>
              <span style={{ color: "#ffffff", Text: "bold" }}>
                ▪ Fulfill and manage your orders.
              </span>{" "}
              We may use your information to fulfill and manage your orders,
              payments, returns, and exchanges made through the Website.
            </p>
            <p>
              <span style={{ color: "#ffffff", Text: "bold" }}>
                ▪ Administer prize draws and competitions.
              </span>{" "}
              We may use your information to administer prize draws and
              competitions when you elect to participate in our competitions.
            </p>
            <p>
              <span style={{ color: "#ffffff", Text: "bold" }}>
                ▪ To deliver and facilitate delivery of services to the user.{" "}
              </span>
              We may use your information to provide you with the requested
              service.
            </p>
            <p>
              <span style={{ color: "#ffffff", Text: "bold" }}>
                ▪ To respond to user inquiries/offer support to users.{" "}
              </span>
              We may use your information to respond to your inquiries and solve
              any potential issues you might have with the use of our Services.
            </p>
            <p>
              <span style={{ color: "#ffffff", Text: "bold" }}>
                ▪ To send you marketing and promotional communications.
              </span>{" "}
              We and/or our third-party marketing partners may use the personal
              information you send to us for our marketing purposes, if this is
              in accordance with your marketing preferences. For example, when
              expressing an interest in obtaining information about us or our
              Website, subscribing to marketing or otherwise contacting us, we
              will collect personal information from you. You can opt-out of our
              marketing emails at any time (see the "WHAT ARE YOUR PRIVACY
              RIGHTS?" below).
            </p>
            <p>
              <span style={{ color: "#ffffff", Text: "bold" }}>
                ▪ Deliver targeted advertising to you.{" "}
              </span>
              We may use your information to develop and display personalized
              content and advertising (and work with third parties who do so)
              tailored to your interests and/or location and to measure its
              effectiveness.
            </p>
            <p>
              <span style={{ color: "#ffffff", Text: "bold" }}>
                ▪ For other business purposes.
              </span>{" "}
              We may use your information for other business purposes, such as
              data analysis, identifying usage trends, determining the
              effectiveness of our promotional campaigns and to evaluate and
              improve our Website, products, marketing and your experience. We
              may use and store this information in aggregated and anonymized
              form so that it is not associated with individual end users and
              does not include personal information.
            </p>
          </div>
          <div className={styles.store}>
            3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
          </div>
          <div className={styles.grop}>
            <span style={{ color: "#ffffff", Text: "bold" }}>In Short: </span>{" "}
            We only share information with your consent, to comply with laws, to
            provide you with services, to protect your rights, or to fulfill
            business obligations.
          </div>
          <div className={styles.store}>
            We may process or share your data that we hold based on the
            following legal basis:
          </div>
          <div className={styles.grop}>
            <p>
              {" "}
              <span style={{ color: "#ffffff", Text: "bold" }}>
                Consent:
              </span>{" "}
              We may process your data if you have given us specific consent to
              use your personal information for a specific purpose.{" "}
            </p>
            <p>
              {" "}
              <span style={{ color: "#ffffff", Text: "bold" }}>
                Legitimate Interests:{" "}
              </span>
              We may process your data when it is reasonably necessary to
              achieve our legitimate business interests.
            </p>
            <p>
              {" "}
              <span style={{ color: "#ffffff", Text: "bold" }}>
                Performance of a Contract:{" "}
              </span>
              Where we have entered into a contract with you, we may process
              your personal information to fulfill the terms of our contract.
            </p>
            <p>
              {" "}
              <span style={{ color: "#ffffff", Text: "bold" }}>
                Legal Obligations:
              </span>{" "}
              We may disclose your information where we are legally required to
              do so in order to comply with applicable law, governmental
              requests, a judicial proceeding, court order, or legal process,
              such as in response to a court order or a subpoena (including in
              response to public authorities to meet national security or law
              enforcement requirements).
            </p>
            <p>
              {" "}
              <span style={{ color: "#ffffff", Text: "bold" }}>
                Vital Interests:{" "}
              </span>
              We may disclose your information where we believe it is necessary
              to investigate, prevent, or take action regarding potential
              violations of our policies, suspected fraud, situations involving
              potential threats to the safety of any person and illegal
              activities, or as evidence in litigation in which we are involved.
            </p>
            <p>
              {" "}
              <span style={{ color: "#ffffff", Text: "bold" }}>
                More specifically, we may need to process your data or share
                your personal information in the following situations:
              </span>
            </p>
            <p>
              {" "}
              <span style={{ color: "#ffffff", Text: "bold" }}>
                Business Transfers.{" "}
              </span>
              We may share or transfer your information in connection with, or
              during negotiations of, any merger, sale of company assets,
              financing, or acquisition of all or a portion of our business to
              another company.
            </p>
          </div>
          <div className={styles.store}>
            4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </div>
          <div className={styles.grop}>
            <p>
              <span style={{ color: "#ffffff", Text: "bold" }}>In Short: </span>{" "}
              We may use cookies and other tracking technologies to collect and
              store your information.
            </p>
            <p>
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to access or store information. Specific
              information about how we use such technologies and how you can
              refuse certain cookies is set out in our Cookie Notice.
            </p>
          </div>
          <div className={styles.store}>
            5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?{" "}
          </div>
          <div className={styles.grop}>
            <p>
              <span style={{ color: "#ffffff", Text: "bold" }}>In Short: </span>{" "}
              We may transfer, store, and process your information in countries
              other than your own.
            </p>
            <p>
              Our servers are located in the United States. If you are accessing
              our Website from outside the United States, please be aware that
              your information may be transferred to, stored, and processed by
              us in our facilities and by those third parties with whom we may
              share your personal information (see "WILL YOUR INFORMATION BE
              SHARED WITH ANYONE?" above), in India, and other countries.
            </p>
            <p>
              If you are a resident in the European Economic Area (EEA) or
              United Kingdom (UK), then these countries may not necessarily have
              data protection laws or other similar laws as comprehensive as
              those in your country. We will however take all necessary measures
              to protect your personal information in accordance with this
              privacy notice and applicable law.
            </p>
          </div>
          <div className={styles.store}>
            European Commission's Standard Contractual Clauses:{" "}
          </div>
          <div className={styles.grop}>
            We have implemented measures to protect your personal information,
            including by using the European Commission's Standard Contractual
            Clauses for transfers of personal information between our group
            companies and between us and our third-party providers. These
            clauses require all recipients to protect all personal information
            that they process originating from the EEA or UK in accordance with
            European data protection laws and regulations. Our Standard
            Contractual Clauses can be provided upon request. We have
            implemented similar appropriate safeguards with our third-party
            service providers and partners and further details can be provided
            upon request.
          </div>
          <div className={styles.store}>
            6. HOW LONG DO WE KEEP YOUR INFORMATION?
          </div>
          <div className={styles.grop}>
            <p>
              <span style={{ color: "#ffffff", Text: "bold" }}>In Short: </span>{" "}
              We keep your information for as long as necessary to fulfill the
              purposes outlined in this privacy notice unless otherwise required
              by law.
            </p>
            <p>
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting or other legal requirements). No purpose in this
              notice will require us keeping your personal information for
              longer than the period of time in which users have an account with
              us.
            </p>
            <p>
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </p>
          </div>
          <div className={styles.store}>
            7. HOW DO WE KEEP YOUR INFORMATION SAFE?
          </div>
          <div className={styles.grop}>
            <p>
              <span style={{ color: "#ffffff", Text: "bold" }}>In Short:</span>{" "}
              We aim to protect your personal information through a system of
              organizational and technical security measures.
            </p>
            <p>
              We have implemented appropriate technical and organizational
              security measures designed to protect the security of any personal
              information we process. However, despite our safeguards and
              efforts to secure your information, no electronic transmission
              over the Internet or information storage technology can be
              guaranteed to be 100% secure, so we cannot promise or guarantee
              that hackers, cybercriminals, or other unauthorized third parties
              will not be able to defeat our security, and improperly collect,
              access, steal, or modify your information. Although we will do our
              best to protect your personal information, transmission of
              personal information to and from our Website is at your own risk.
              You should only access the Website within a secure environment.
            </p>
          </div>{" "}
          <div className={styles.store}>
            8. DO WE COLLECT INFORMATION FROM MINORS?ICY
          </div>
          <div className={styles.grop}>
            <p>
              <span style={{ color: "#ffffff", Text: "bold" }}>In Short:</span>{" "}
              We do not knowingly collect data from or market to children under
              18 years of age.
            </p>
            <p>
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the Website, you represent that you are
              at least 18 or that you are the parent or guardian of such a minor
              and consent to such minor dependent’s use of the Website. If we
              learn that personal information from users less than 18 years of
              age has been collected, we will deactivate the account and take
              reasonable measures to promptly delete such data from our records.
              If you become aware of any data we may have collected from
              children under age 18, please contact us at hello@cyberdogz.io.
            </p>
          </div>
          <div className={styles.store}>9. WHAT ARE YOUR PRIVACY RIGHTS?</div>
          <div className={styles.grop}>
            <p>
              <span style={{ color: "#ffffff", Text: "bold" }}>In Short:</span>{" "}
              In some regions, such as the European Economic Area (EEA) and
              United Kingdom (UK), you have rights that allow you greater access
              to and control over your personal information. You may review,
              change, or terminate your account at any time.
            </p>
            <p>
              In some regions (like the EEA and UK), you have certain rights
              under applicable data protection laws. These may include the right
              (i) to request access and obtain a copy of your personal
              information, (ii) to request rectification or erasure; (iii) to
              restrict the processing of your personal information; and (iv) if
              applicable, to data portability. In certain circumstances, you may
              also have the right to object to the processing of your personal
              information. To make such a request, please use the contact
              details provided below. We will consider and act upon any request
              in accordance with applicable data protection laws.
            </p>
            <p>
              If we are relying on your consent to process your personal
              information, you have the right to withdraw your consent at any
              time. Please note however that this will not affect the lawfulness
              of the processing before its withdrawal, nor will it affect the
              processing of your personal information conducted in reliance on
              lawful processing grounds other than consent.
            </p>
            <p>
              If you are a resident in the EEA or UK and you believe we are
              unlawfully processing your personal information, you also have the
              right to complain to your local data protection supervisory
              authority. You can find their contact details here:
              https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
            </p>
            <p>
              If you are a resident in Switzerland, the contact details for the
              data protection authorities are available here:
              <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
                {" "}
                https://www.edoeb.admin.ch/edoeb/en/home.html.
              </a>
            </p>
            <p>
              If you have questions or comments about your privacy rights, you
              may email us at
              <a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=hello@CYBERDOGZ.io">
                {" "}
                hello@cyberdogz.io.
              </a>
            </p>
          </div>
          <div className={styles.store}>Account Information</div>
          <div className={styles.grop}>
            <p>
              If you would at any time like to review or change the information
              in your account or terminate your account, you can: Upon your
              request to terminate your account, we will deactivate or delete
              your account and information from our active databases. However,
              we may retain some information in our files to prevent fraud,
              troubleshoot problems, assist with any investigations, enforce our
              Terms of Use and/or comply with applicable legal requirements.
            </p>
            <p>
              Cookies and similar technologies: Most Web browsers are set to
              accept cookies by default. If you prefer, you can usually choose
              to set your browser to remove cookies and to reject cookies. If
              you choose to remove cookies or reject cookies, this could affect
              certain features or services of our Website. To opt-out of
              interest-based advertising by advertisers on our Website visit{" "}
              <a href="http://www.aboutads.info/choices/">
                http://www.aboutads.info/choices/.
              </a>
            </p>
            <p>
              Opting out of email marketing: You can unsubscribe from our
              marketing email list at any time by clicking on the unsubscribe
              link in the emails that we send or by contacting us using the
              details provided below. You will then be removed from the
              marketing email list — however, we may still communicate with you,
              for example to send you service-related emails that are necessary
              for the administration and use of your account, to respond to
              service requests, or for other non-marketing purposes. To
              otherwise opt-out, you may:
            </p>
            <p> ▪ Contact us using the contact information provided.</p>
          </div>
          <div className={styles.store}>
            10. CONTROLS FOR DO-NOT-TRACK FEATURES
          </div>
          <div className={styles.grop}>
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ("DNT") feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.{" "}
          </div>
          <div className={styles.store}>
            11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </div>
          <div className={styles.grop}>
            <p>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</p>
            <p>
              California Civil Code Section 1798.83, also known as the "Shine
              The Light" law, permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.
            </p>
            <p>
              If you are under 18 years of age, reside in California, and have a
              registered account with the Website, you have the right to request
              removal of unwanted data that you publicly post on the Website. To
              request removal of such data, please contact us using the contact
              information provided below, and include the email address
              associated with your account and a statement that you reside in
              California. We will make sure the data is not publicly displayed
              on the Website, but please be aware that the data may not be
              completely or comprehensively removed from all our systems (e.g.
              backups, etc.).
            </p>
          </div>
          <div className={styles.store}>CCPA Privacy Notice</div>
          <div className={styles.grop}>
            <p>The California Code of Regulations defines a "resident" as:</p>
            <p>
              (1) every individual who is in the State of California for other
              than a temporary or transitory purpose and (2) every individual
              who is domiciled in the State of California who is outside the
              State of California for a temporary or transitory purpose
            </p>
            <p>All other individuals are defined as "non-residents."</p>
            <p>
              If this definition of "resident" applies to you, we must adhere to
              certain rights and obligations regarding your personal
              information.
            </p>
          </div>
          <div className={styles.store}>
            What categories of personal information do we collect?
          </div>
          <div className={styles.grop}>
            We have collected the following categories of personal information
            in the past twelve (12) months:
            <br />
            <p>
            <div className={styles.item}>
              <div className={styles.history_table}>
                <div className={styles.rowdata}>
                  <Row>
                    {/* <Col span={1}></Col> */}
                    <Col span={8}>
                      {" "}
                      <div className={styles.seller}>Category</div>
                    </Col>
                    <Col span={13}>
                      <div className={styles.seller}>Examples</div>
                    </Col>
                    <Col span={3}>
                      <div className={styles.seller}>Collected</div>
                    </Col>
                  </Row>
                </div>
                {/* <img src={HistoryShade} className={styles.history_shade} /> */}
                {/* <Loader className={styles.loader} /> */}
               {data.map((x,index) => (
                <div className={styles.rowdata} key={index}>
                  <Row>
                    <Col span={8}>
                      <div className={styles.raceText}>     
                      {x.category}                                         
                      </div>
                    </Col>
                    <Col span={13}>
                      <div className={styles.raceText}>
                        {x.examples}
                      </div>
                    </Col>
                    <Col span={3}>
                      <div className={styles.raceText}>
                        {x.collected}
                      </div>
                    </Col>
                  </Row>
                </div>
                ))}
                {/* <div>
                  <Pagination
                    onChange={(page) => {
                      if(page === 1){
                      setPage(2)
                    }
                    else setPage(1)
                    }}
                    total={totalDocs}
                    current={page}
                  />
                </div> */}
              </div>
              </div>
              , We may also collect other personal information outside of these
              categories instances where you interact with us in-person, online,
              or by phone or mail in the context of:
            </p>
            <p> ▪ Receiving help through our customer support channels;</p>
            <p> ▪ Participation in customer surveys or contests; and</p>
            <p>
              {" "}
              ▪ Facilitation in the delivery of our Services and to respond to
              your inquiries.
            </p>
          </div>
          <div className={styles.store}>
            How do we use and share your personal information?
          </div>
          <div className={styles.grop}>
            <p>
              More information about our data collection and sharing practices
              can be found in this privacy notice.
            </p>
            <p>
              You may contact us by email at{" "}
              <a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=hello@CYBERDOGZ.io">
                {" "}
                hello@cyberdogz.io,
              </a>
              , or by referring to the contact details at the bottom of this
              document.
            </p>
            <p>
              If you are using an authorized agent to exercise your right to
              opt-out we may deny a request if the authorized agent does not
              submit proof that they have been validly authorized to act on your
              behalf.
            </p>
          </div>
          <div className={styles.store}>
            Will your information be shared with anyone else?
          </div>
          <div className={styles.grop}>
            <p>
              We may disclose your personal information with our service
              providers pursuant to a written contract between us and each
              service provider. Each service provider is a for-profit entity
              that processes the information on our behalf.
            </p>
            <p>
              We may use your personal information for our own business
              purposes, such as for undertaking internal research for
              technological development and demonstration. This is not
              considered to be "selling" of your personal data.
            </p>
            <p>
              CYBERDOGZ Decentralized Autonomous Organization (DAO), Affiliates
              and Community has not disclosed or sold any personal information
              to third parties for a business or commercial purpose in the
              preceding 12 months. CYBERDOGZ Decentralized Autonomous
              Organization (DAO), Affiliates and Community will not sell
              personal information in the future belonging to website visitors,
              users and other consumers.
            </p>
          </div>
          <div className={styles.store}>
            Your rights with respect to your personal data
          </div>
          <div className={styles.grop}>
            <p>
              <u>Right to request deletion of the data - Request to delete</u>
            </p>
            <br />
            <p>
              You can ask for the deletion of your personal information. If you
              ask us to delete your personal information, we will respect your
              request and delete your personal information, subject to certain
              exceptions provided by law, such as (but not limited to) the
              exercise by another consumer of his or her right to free speech,
              our compliance requirements resulting from a legal obligation or
              any processing that may be required to protect against illegal
              activities.
            </p>
            <p>
              <u>Right to be informed - Request to know</u>
            </p>
          </div>
          <div className={styles.store}>
            Depending on the circumstances, you have a right to know:{" "}
          </div>
          <div className={styles.grop}>
            <p> ▪ whether we collect and use your personal information;</p>
            <p> ▪ the categories of personal information that we collect;</p>
            <p>
              {" "}
              ▪ the purposes for which the collected personal information is
              used;
            </p>
            <p>
              {" "}
              ▪ whether we sell your personal information to third parties;
            </p>
            <p>
              {" "}
              ▪ the categories of personal information that we sold or disclosed
              for a business purpose;
            </p>
            <p>
              {" "}
              ▪ the categories of personal information that we sold or disclosed
              for a business purpose;
            </p>
            <p>
              {" "}
              ▪ the categories of personal information that we sold or disclosed
              for a business purpose;
            </p>
            <p>
              {" "}
              ▪ In accordance with applicable law, we are not obligated to
              provide or delete consumer information that is de-identified in
              response to a consumer request or to re-identify individual data
              to verify a consumer request.
            </p>
            <p>
              <u>
                Right to Non-Discrimination for the Exercise of a Consumer’s
                Privacy Rights
              </u>
            </p>
            <br />
            <p>
              We will not discriminate against you if you exercise your privacy
              rights.
            </p>
          </div>
          <div className={styles.store}>
            <u>Verification process </u>
          </div>
          <div className={styles.grop}>
            <p>
              Upon receiving your request, we will need to verify your identity
              to determine you are the same person about whom we have the
              information in our system. These verification efforts require us
              to ask you to provide information so that we can match it with
              information you have previously provided us. For instance,
              depending on the type of request you submit, we may ask you to
              provide certain information so that we can match the information
              you provide with the information we already have on file, or we
              may contact you through a communication method (e.g. phone or
              email) that you have previously provided to us. We may also use
              other verification methods as the circumstances dictate.
            </p>
            <p>
              We will only use personal information provided in your request to
              verify your identity or authority to make the request. To the
              extent possible, we will avoid requesting additional information
              from you for the purposes of verification. If, however, we cannot
              verify your identity from the information already maintained by
              us, we may request that you provide additional information for the
              purposes of verifying your identity, and for security or
              fraud-prevention purposes. We will delete such additionally
              provided information as soon as we finish verifying you.
            </p>
          </div>
          <div className={styles.store}>
            <u>Other privacy rights </u>
          </div>
          <div className={styles.grop}>
            <p> ▪ you may object to the processing of your personal data.</p>
            <p>
              {" "}
              ▪ you may request correction of your personal data if it is
              incorrect or no longer relevant, or ask to restrict the processing
              of the data.
            </p>
            <p>
              {" "}
              ▪ you can designate an authorized agent to make a request under
              the CCPA on your behalf. We may deny a request from an authorized
              agent that does not submit proof that they have been validly
              authorized to act on your behalf in accordance with the CCPA.
            </p>
            <p>
              {" "}
              ▪ you may request to opt-out from future selling of your personal
              information to third parties. Upon receiving a request to opt-out,
              we will act upon the request as soon as feasibly possible, but no
              later than 15 days from the date of the request submission.
            </p>
            <p>
              To exercise these rights, you can contact us by email at{" "}
              <a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=hello@CYBERDOGZ.io">
                {" "}
                hello@cyberdogz.io
              </a>
              , or by referring to the contact details at the bottom of this
              document. If you have a complaint about how we handle your data,
              we would like to hear from you.{" "}
            </p>
          </div>
          <div className={styles.store}>
            12. DO WE MAKE UPDATES TO THIS NOTICE?{" "}
          </div>
          <div className={styles.grop}>
            <p>
              <span style={{ color: "#ffffff", Text: "bold" }}>In Short: </span>{" "}
              Yes, we will update this notice as necessary to stay compliant
              with relevant laws.
            </p>
            <p>
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated "Revised" date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.
            </p>
          </div>
          <div className={styles.store}>
            13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </div>
          <div className={styles.grop}>
            <p>
              If you have questions or comments about this notice, you may email
              us at{" "}
              <a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=hello@CYBERDOGZ.io">
                {" "}
                hello@cyberdogz.io.
              </a>{" "}
              or by post to:
            </p>
            <p>CYBERDOGZ Decentralized Autonomous Organization (DAO),</p>
            <p> Affiliates and Community</p>
        
          </div>
          <div className={styles.store}>
            14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </div>
          <div className={styles.grop}>
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it in some circumstances. To
            request to review, update, or delete your personal information,
            please submit a request form by clicking{" "}
            <a href="https://app.termly.io/notify/c1b33712-d924-4ea2-a9fe-98e1955ccf3a">
              link{" "}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacypolicy;
