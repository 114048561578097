import React, { useEffect, useRef, useState } from "react";
import Styles from "./Race.module.sass";
import {
  GetPlayableAssets,
  GetRaceEvents,
  GetRaceLeaderbord,
} from "../../services/ApiServices";
import cn from "classnames";
import moment from "moment";
import Modal from "../../components/NewModal";
import SelectModal from "./SelectModal";
import LeaderBoard from "./LeaderBoard";
import { useWeb3React } from "@web3-react/core";
import CashRace from "./Cash-Race";
import Countdown from "antd/lib/statistic/Countdown";
import Dots from "../../images/raceDots.png";
import Dots1 from "../../images/raceDots1.png";
import BgImage from "../../images/raceImgNew.png";
const Race = (props) => {
  const [events, setEvents] = useState([]);
  const [liveEvents, setLiveEvents] = useState([]);
  const [leaderboard, setLeaderboard] = useState([]);
  const [alltimeLeaderboard, setAlltimeLeaderboard] = useState([]);
  const [eventsLoading, setEventsLoading] = useState(false);
  const [leaderboardLoading, setLeaderboardLoading] = useState(false);
  const [alltimeLeaderboardLoading, setAlltimeLeaderboardLoading] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectModal, setIsSelectModal] = useState(false);
  const [assets, setAssets] = useState([]);
  const [asset, setAsset] = useState({});
  const { account, active, activate, error, deactivate } = useWeb3React();
  const [page, setPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  const [leaderboardPage, setLeaderboardPage] = useState(1);
  const [leaderboardTotalDocs, setLeaderboardTotalDocs] = useState(0);
  const [alltimeLeaderboardPage, setAlltimeLeaderboardPage] = useState(1);
  const [alltimeLeaderboardTotalDocs, setAlltimeLeaderboardTotalDocs] =
    useState(0);
  const [nextRaceTime, setNextRaceTime] = useState(0);
  const [isCheck, setIsCheck] = useState(true);

  const nextRace = () => {
    let currentMinute = moment.utc().minutes();
    let defaultTime;
    if (currentMinute >= 0 && currentMinute <= 14) {
      defaultTime = 15 - currentMinute;
    } else if (currentMinute >= 15 && currentMinute <= 29) {
      defaultTime = 30 - currentMinute;
    } else if (currentMinute >= 30 && currentMinute <= 44) {
      defaultTime = 45 - currentMinute;
    } else if (currentMinute >= 45 && currentMinute <= 59) {
      defaultTime = 60 - currentMinute;
    }

    setNextRaceTime(moment().utc().add(defaultTime, "minutes").valueOf());
  };
  useEffect(() => {
    nextRace();
  }, []);

  useEffect(() => {
    if (account) getAssets(account);
  }, [account]);

  const getAssets = async () => {
    setIsLoading(true);
    let result;
    try {
      result = await GetPlayableAssets(account);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
    try {
      if (result.success) {
        setIsLoading(false);
        setAssets(result.result);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const interval = useRef();

  useEffect(() => {
    interval.current = setInterval(() => {
      const currentMinute = moment.utc().minutes();
      const currentSecond = moment.utc().seconds();
      if (currentMinute % 15 == 0 && currentSecond == 0) {
        setNextRaceTime(moment().utc().add(15, "minutes").valueOf());
      }
      if (currentMinute % 15 == 0 && currentSecond == 20) {
        getRaceEvents();
      }
    }, 1000);
    return () => {
      clearInterval(interval.current);
      interval.current = null;
    };
  }, []);
  useEffect(() => {}, [nextRaceTime]);
  useEffect(() => {
    getRaceEvents();
  }, [page, isCheck]);
  const getRaceEvents = async () => {
    setEventsLoading(true);
    let result;
    try {
      result = await GetRaceEvents(page, isCheck);
    } catch (e) {
      console.log(e);
      setEventsLoading(false);
    }
    try {
      if (result.success) {
        setEventsLoading(false);
        setEvents(result.rooms.docs);
        setTotalDocs(result.rooms.totalDocs);
        let liveEvents = result.rooms.docs.filter((event) => event.registrable);
        liveEvents = liveEvents.slice(0, 3);
        setLiveEvents(liveEvents);
      }
    } catch (e) {
      console.log(e);
      setEventsLoading(false);
    }
  };
  useEffect(() => {
    getRaceLeaderbord();
  }, [leaderboardPage]);
  useEffect(() => {
    getAlltimeLeaderbord();
  }, [alltimeLeaderboardPage]);
  const getRaceLeaderbord = async () => {
    setLeaderboardLoading(true);
    let result;
    try {
      result = await GetRaceLeaderbord(true, leaderboardPage);
    } catch (e) {
      console.log(e);
      setLeaderboardLoading(false);
    }
    try {
      if (result.success) {
        setLeaderboardLoading(false);
        setLeaderboard(result.leaderboard.docs);
        setLeaderboardTotalDocs(result.leaderboard.totalDocs);
      }
    } catch (e) {
      console.log(e);
      setLeaderboardLoading(false);
    }
  };
  const getAlltimeLeaderbord = async () => {
    setAlltimeLeaderboardLoading(true);
    let result;
    try {
      result = await GetRaceLeaderbord(false, alltimeLeaderboardPage);
    } catch (e) {
      console.log(e);
      setAlltimeLeaderboardLoading(false);
    }
    try {
      if (result.success) {
        setAlltimeLeaderboardLoading(false);
        setAlltimeLeaderboard(result.leaderboard.docs);
        setAlltimeLeaderboardTotalDocs(result.leaderboard.totalDocs);
      }
    } catch (e) {
      console.log(e);
      setAlltimeLeaderboardLoading(false);
    }
  };
  const activeOnly = (isCheck) => {
    setIsCheck(isCheck);
  };
  return (
    <div className={cn("container")}>
      <>
        <div className={Styles.raceBg}>
          <img src={BgImage} className={Styles.raceBg1} />
          <div className={Styles.raceMask}>
            <h2>Welcome to the new Race game</h2>
            <p>All available race tracks… with a race every 15 mins.</p>
          </div>
        </div>
        <div className={Styles.background}>
          <div className={Styles.raceKibbles}>
            <img src={Dots} className={Styles.dot} />
            <div>
              <h3>
                USE <span style={{ color: "#FDC22E" }}>$KIBBLES</span> FOR EVERY
                RACE
              </h3>
              <p>
                Check out races and Select your race and your CYBERDOGZ from the
                collection below
              </p>
              <div className={Styles.nextRace}>
                <div className={Styles.whiteText}>
                  Next race begins in:&nbsp;
                </div>
                <div className={Styles.minsTimer}>
                  <Countdown value={nextRaceTime} format="mm:ss" />
                </div>
              </div>
            </div>
            <img src={Dots1} className={Styles.dot1} />
          </div>
          {/* <p className={Styles.head}>
          <span className={Styles.yield}> CYBERDOGZ</span> RACE
        </p>
        <div className={Styles.container}>
          <Row>
            <Col lg={10} md={10} xs={24}>
              <img src={Gamesdogz} className={Styles.raceImg} />
            </Col>
            <Col lg={14} md={14} xs={24}>
              <div className={Styles.body}>
                <div className={Styles.whiteHead}>
                  Welcome to the new Race game !
                </div>
                <br />
                <div className={Styles.whiteText}>
                  All available race tracks… with a race every 15 mins.
                </div>
                <div className={Styles.orangeText}>
                  Use Kibbles for every race
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className={Styles.scroll}>
          <div className={Styles.whiteText}>
            Check out races and Select your race and your CYBERDOGZ from the
            collection below
          </div>
          <img src={Shade} className={Styles.shade} />
        </div>
        <div className={Styles.liveHeader}>
          <p className={Styles.liveHead}>
            <span className={Styles.yield}> LIVE</span> RACE
          </p>
          <div className={Styles.nextRace}>
            <div className={Styles.whiteText}>Next race begins in:&nbsp;</div>
            <div className={Styles.minsTimer}>
              <Countdown value={nextRaceTime} format="mm:ss" />
            </div>
          </div>
        </div> */}
          {/* <Row style={{ justifyContent: "center" }}>
          {liveEvents.map((event, index) => (
            <Col sm={24} lg={8} md={8} xs={24} key={index}>
              <div className={Styles.live} id={index}>
                <div className={Styles.liveBody}>
                  <div className={Styles.liveBorder}></div>
                  <div className={Styles.liveContainer}>
                    <div className={Styles.liveBody}>
                      <div className={Styles.orangeShade}>
                        {event.name} #{event.roomNumber}
                      </div>
                      <div className={Styles.viewButtonContainer}>
                        <Link
                          to={"/race/live/" + event.gameId}
                          className={Styles.viewButton}
                        >
                          View Now
                        </Link>
                      </div>
                    </div>
                    <div className={Styles.liveRace}>
                      Race - {event.batch}
                      <b> | </b>
                      {moment(event.timestamp * 1000)
                        .utc()
                        .format("hh:mm A")}
                    </div>
                    <br />
                    <Row>
                      <Col md={6} sm={6} xs={6} className={Styles.border}>
                        <b>
                          <div className={Styles.field}>
                            {event.registeredDogs.length} / 10
                          </div>
                        </b>
                        <div className={Styles.text}>CYBERDOGZ</div>
                      </Col>
                      <Col md={6} sm={7} xs={7} className={Styles.border}>
                        <b>
                          <div className={Styles.yield}>
                            {event.toWin} $Bonez
                          </div>
                        </b>
                        <div className={Styles.text}>To Win</div>
                      </Col>
                      <Col md={5} sm={5} xs={4} className={Styles.border}>
                        <div className={Styles.field}> {event.totalBet}</div>
                        <div className={Styles.text}>Total Bet</div>
                      </Col>
                      <Col md={7} sm={7} xs={7}>
                        <div className={Styles.blueText}>
                          {event.lapLength} m
                        </div>
                        <div className={Styles.text}>Track distance</div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row> */}
          {/* <div className={Styles.timer}>
          <div className={Styles.nextRace}>
            <div className={Styles.whiteText}>Next race begins in</div>
            <div className={Styles.minsTimer}>
              <Countdown value={nextRaceTime} format="mm:ss" />
            </div>
          </div>

          {/* <div className={Styles.nextDatRace}>
              <div className={Styles.dayTimer}>
                <div className={Styles.whiteText}>
                  “Race of the Day” <br />
                  begins in
                </div>
                <div className={Styles.dayTime}>
                  <CounDown asset={countdown4hr} fromHour={true} />
                </div>
              </div>
            </div> 
        </div> */}
          <CashRace
            events={events}
            setPage={(page) => {
              setPage(page);
            }}
            totalDocs={totalDocs}
            page={page}
            eventsLoading={eventsLoading}
            isLoading={isLoading}
            assets={assets}
            refreshRaceEvents={getRaceEvents}
            activeOnly={activeOnly}
          />
        </div>
        <LeaderBoard
          leaderboard={leaderboard}
          leaderboardLoading={leaderboardLoading}
          leaderboardPage={leaderboardPage}
          leaderboardTotalDocs={leaderboardTotalDocs}
          setLeaderboardPage={(page) => {
            setLeaderboardPage(page);
          }}
          alltimeLeaderboard={alltimeLeaderboard}
          alltimeLeaderboardLoading={alltimeLeaderboardLoading}
          alltimeLeaderboardPage={alltimeLeaderboardPage}
          alltimeLeaderboardTotalDocs={alltimeLeaderboardTotalDocs}
          setAlltimeLeaderboardPage={(page) => {
            setAlltimeLeaderboardPage(page);
          }}
        />
        <Modal
          visible={isSelectModal}
          outerClassName={Styles.modal}
          onClose={() => setIsSelectModal(false)}
        >
          <SelectModal asset={asset} />
        </Modal>
      </>
    </div>
  );
};
export default Race;
