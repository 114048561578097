//------------------------------------- KEYS -------------------------------------
export const ALCHEMY_PROVIDER_KEY = "WExgUJhXG09OjV2wJd9F3ueftC_Q-otd";

export const INFURA_PROVIDER_KEY = "19ba881bc9cd40cab8757be6f5ea1c50";

//------------------------------------- NFT ADDRESS -------------------------------------

export const ETHEREUM_NFT_ADDRESS =
  "0x459124c1320BB66a2D2e58E04E7D28C344631Ded";

export const POLYGON_NFT_ADDRESS = "0x6c9cbd0be01768f11865dafaf41a10ce40fbb7e3";

export const RINKEBY_NFT_ADDRESS = "0xbD14f20eE32553e28F75488868A39Dce006fbC6f";

export const BREEDING_NFT_ADDRESS =
  "0x89c04c87af256471b480c223163f08b2043bdc61";

//------------------------------------- BONEZ TOKEN ADDRESS -------------------------------------

export const BONEZ_TOKEN_ADDRESS = "0xcF79651fBae99559127764a5ab808747fa3391ce";

export const COMMON_MARKET_ADDRESS = "0x67844BD060D0119e3717D2f63eFE9500C062BE00";

export const TRANSFER_MARKET_ADDRESS = "0x9f01AFc275EACA881d37249Ea8F06400D28F56eB";


//------------------------------------- RENT ADDRESS -------------------------------------

export const ETHEREUM_RENT_MANEGER_ADDRESS =
  "0x9Fa958F64b7cC0F9C305B33620b7f66Ca86d6c2A";

export const RINKEBY_RENT_MANEGER_ADDRESS =
  "0xaB1273EC34a156A985dEfAD873607B8b646093Ed";

//------------------------------------- CAPSULE ADDRESS -------------------------------------

export const ETHEREUM_CAPSULE_MARKET_ADDRESS =
  "0x870b7d8c9E1157f3aED10331E608CA428C1Ff071";

//------------------------------------- LAND ADDRESS -------------------------------------

export const ANDROMEDA_ADDRESS = "0x474ABA21846bF2862946c63bDfF6e479D03455a2";

//------------------------------------- AUCTION ADDRESS -------------------------------------

export const AUCTION_ADDRESS = "0x14D7c2d970cDcD402c86349e3956B55EFa255CA4";

//------------------------------------- LANDER ADDRESS -------------------------------------

export const ETHEREUM_LANDER_MINT_ADDRESS =
  "0x26c35c71AdC809cCab920b0a796e02587B633E39";

export const RINKEBY_LANDER_MINT_ADDRESS =
  "0x1B4C5a2A4C6e662701f67777F6D38e097D754Ed6";

//------------------------------------- KIBBLEZ Generator -------------------------------------

export const MUMBAI_MATIC_ACCESSORIES_MARKET =
  "0x8C61D1457369C038524112010C353Eb863159b76";

export const MATIC_ACCESSORIES_MARKET =
  "0x37276A9F38872a44ca7B95a0c3D62a82283BCF6b";

//------------------------------------- SPACESGIP CRATE -------------------------------------

export const RINKEBY_SPACESHIPCRATE_NFT =
  "0xE6dC9BAfB1719F5cAa54e509FBD72254c5c42f9C";

export const RINKEBY_SPACESHIPCRATE_MARKET =
  "0x705D7Fd134A915c8354581541cC26eF5DeAcB517";

export const MAINNET_SPACESHIPCRATE_NFT =
  "0x3C2a2Fb58eE07775FDC302D00Db6023C4477AfdE";

export const MAINNET_SPACESHIPCRATE_MARKET =
  "0x7a0AD81aac9268a668355BBd4DcaA33C360e4bAE";

//------------------------------------- TRANSFER ADDRESS -------------------------------------

export const RINKEBY_NFT_TRANSFER_ADDRESS =
  "0x7417177E4E9a16eF3B3C7093ae65d3E19116f8b5";

export const ETHEREUM_NFT_TRANSFER_ADDRESS =
  "0xF260821C37d76C249361064a1681BB2c2a1Eea1a";

//------------------------------------- BREEDING ADDRESS -------------------------------------

export const MUMBAI_MATIC_BREEDING_ADDRESS =
  "0x2d19FFafe3Aa7719b7608DE9f7B92e1cba7C5970";

export const POLYGON_BREEDING_ADDRESS =
  "0x09f944c99450b3397eb8c16318834cb39e846ee0";

export const USDT_TOKEN_ADDRESS = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";

export const MUMBAI_USDT_TOKEN_ADDRESS = "0x5e0f4c197850Fa556477651EEB5CbE88ee9236B9";

export const MUMBAI_USDT_TOKEN_ADDRESS1 = "0xB7a0b766E8BFF56a29F033CE0b821565950B0D87";


//------------------------------------- ASSET IDs -------------------------------------

export const PUPPY_ASSET_ID = "62a1ba696c418d7aa8093742";

//------------------------------------- SIGNATURE IDs -------------------------------------

export const SIGNATURE_MESSAGE_KEY = "kGFCxvda2GsWxe7DG6XW";

//------------------------------------- Game URL -------------------------------------

export const RACE_URL = "https://dog-race.cyberdogz.io?gameId=";

export const RACE_REPLAY_URL = "https://dog-race.cyberdogz.io/Cyberdogz_replay/index.php?replay=";

export const SPACE_GAME_URL = "https://spacebattle.cyberdogz.io/";

//------------------------------------- Spin Wheel -------------------------------------

export const SPIN_WHEEL_REWARDS = [
  "Loss",
  "Rare CYBERDOGZ",
  "1000 $KIBBLEZ",
  "Gem Cardz",
  "100 $KIBBLEZ",
  "Female Lander",
  "10 $BONEZ",
  "Alpha CYBERDOGZ",
  "1 $KIBBLEZ",
  "Loss",
  "100 $BONEZ",
  "Free Spin",
];

//------------------------------------- Assets ADDRESS -------------------------------------
export const ASSET_ADDRESS = "0x1652149105D6d5F41844B1104499d0C2E4930ee7";
