import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Link, useParams } from "react-router-dom";
import styles from "./Table.module.sass";
// import Icon from "../../components/Icon";
import WinnersLeaderboard from "./WinnersLeaderboard";
import AllTimeLeaderboard from "./AllTimeLeaderboard";
import { Col, Row } from "antd";


const LeaderBoard = ({
  leaderboard,
  leaderboardLoading,
  leaderboardPage,
  leaderboardTotalDocs,
  setLeaderboardPage,
  alltimeLeaderboard,
  alltimeLeaderboardLoading,
  alltimeLeaderboardPage,
  alltimeLeaderboardTotalDocs,
  setAlltimeLeaderboardPage
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [polygonAssets, setPolygonAssets] = useState([]);
  const [ethereumAssets, setEthereumAssets] = useState([]);
  const { uid } = useParams();

  return (
    <div className={cn("container", styles.container)}>
      <div className={styles.group}>
        {/* <Row>
          <Col xs={24} md={12} lg={12}>
            <div className={styles.leftTable}> */}
            {/* <div className={styles.item}>
              <WinnersLeaderboard
                leaderboard={leaderboard}
          leaderboardLoading={leaderboardLoading}
          leaderboardPage={leaderboardPage}
          leaderboardTotalDocs={leaderboardTotalDocs}
          setLeaderboardPage={setLeaderboardPage}
              />
              </div> */}
          {/*  </div>
           </Col>
          <Col xs={24} md={12} lg={12}>
            <div className={styles.rightTable}> */}
            <div className={styles.item}>
              <AllTimeLeaderboard
                className={styles.rightTable}
                index={activeIndex}
                alltimeLeaderboard={alltimeLeaderboard}
          alltimeLeaderboardLoading={alltimeLeaderboardLoading}
          alltimeLeaderboardPage={alltimeLeaderboardPage}
          alltimeLeaderboardTotalDocs={alltimeLeaderboardTotalDocs}
          setAlltimeLeaderboardPage={setAlltimeLeaderboardPage}
              />
              </div>
            {/* </div>
          </Col>
        </Row> */}
      </div>
    </div>
  );
};

export default LeaderBoard;
