import React, { useEffect, useRef, useState } from "react";
import Styles from "./ChallengeModal.module.sass";
import { Row, Col } from "antd";
import Shade from "../../../images/stakeshade.png";
import {
  GetGameInfo,
  GetTimeLapseGameInfo,
  GetUserAssets,
} from "../../../services/ApiServices";
import Leftborder from "../../../images/leftborder.png";
import Loader from "../../../components/Loader";
import Bonze from "../../../images/bonez.png";
import Kibblez from "../../../images/kibblez.png";
import Button from "../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import CountDown from "../../../components/CountDown";
import TextInput from "../../../components/TransparentTextInput";
import PrimaryButton from "../../../components/PrimaryButton";
const ChallengeModal = ({ asset, GameShow, countdown4hr, countdown24hr }) => {
  const state = useSelector((state) => state);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isType1Loading, setIsType1Loading] = useState(false);
  const [isType2Loading, setIsType2Loading] = useState(false);
  const [user, setUser] = useState({});
  const [name, setName] = useState(
    state.User.user.userName ? state.User.user.userName : ""
  );
  const [error, setError] = useState("");
  useEffect(() => {
    setUser(state.User.user);
    if (state.User.user) {
      setName(state.User.user.userName);
      setUser(state.User.user);
    }
  }, [state.User.user]);
  const getUserInfo = async (type) => {
    if (name) {
      if (user.token) {
        let result;
        try {
          result = await GetTimeLapseGameInfo(asset.tokenId, user.token);
        } catch (e) {
          console.log(e);
          setIsType1Loading(false);
          setIsType2Loading(false);
          enqueueSnackbar(e.message, { variant: "error" });
          // GameShow(name,user.uid,asset.token.asset.gameId,asset.tokenId,type==0?"4HR":"24HR")
        }
        try {
          if (result.success) {
            setIsType1Loading(false);
            setIsType2Loading(false);
            GameShow(asset.tokenId, name, user.token);
            enqueueSnackbar(result.message, { variant: "info" });
          }
        } catch (e) {
          setIsType2Loading(false);
          setIsType1Loading(false);
          console.log(e);
        }
      } else {
        enqueueSnackbar("User Not found", { variant: "error" });
      }
    } else {
      setIsType2Loading(false);
      setIsType1Loading(false);
      setError("Username is required");
    }
  };
  const nameHandler = (e) => {
    setError("");
    if (e.target.value.length <= 3) {
      setError("Username should be minimum 3 characters");
    } else {
      setError("");
      setName(e.target.value);
    }
  };

  return (
    <div className={Styles.stake}>
      <div className={Styles.head}>
        <span className={Styles.yield}>START</span> CHALLENGE
      </div>
      <Row>
        <Col md={12} sm={24} xs={24}>
          <div className={Styles.imageDiv}>
            <img src={asset.image} className={Styles.previewImg} />
          </div>
        </Col>
        <Col md={12} sm={24} xs={24}>
          <div className={Styles.input}>
            <TextInput
              autocomplete="false"
              label="Display name"
              onChange={nameHandler}
              // defaultValue={user.userName?user.userName:""}
              value={name}
            />
          </div>
        </Col>
      </Row>
      {error && <div className={Styles.error}>{error}</div>}
      {/* <img src={Shade} className={Styles.shade} /> */}

      <div className={Styles.challengeText}>4 hour challenge</div>
      <div className={Styles.wager}>
        Wager <img src={Kibblez} />
        100 to win <img src={Bonze} />3
      </div>

      <div className={Styles.button}>
        <PrimaryButton
          label="Start Now"
          loading={isType1Loading}
          loadingtext="Starting"
          onClick={() => {
            setIsType1Loading(true);
            getUserInfo(0);
          }}
        />
      </div>
      <div className={Styles.coundown}>
        Ends in <CountDown asset={countdown4hr} fromHour={true} />
      </div>
      {/* <img src={Shade} className={Styles.shade} />

      <div className={Styles.challengeText}>24 hour challenge</div>
      <div className={Styles.wager}>
        Wager <img src={Kibblez} />
        10,000 to win <img src={Bonze} />
        100
      </div>

      <div className={Styles.button}>
        <Button
          label="Not Available"
          loading={isType2Loading}
          loadingtext="Starting"
          // onClick={() => {
          //   setIsType2Loading(true);
          //   getUserInfo(1);
          // }}
        />
     
      </div>
      <div className={Styles.coundown}>
       Ends in <CountDown asset={countdown24hr} fromHour={true} />
      </div> */}
    </div>
  );
};
export default ChallengeModal;
