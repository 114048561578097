import React, { useEffect, useRef, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { Col, Row } from "antd";
import moment from "moment";
import cn from "classnames";
import { RACE_REPLAY_URL, RACE_URL } from "../../../Config/config";
import {
  GetRaceEvents,
  GetRaceLeaderbord,
} from "../../../services/ApiServices";
import Styles from "../../Race/Race.module.sass";
import styles from "./Race.module.sass";
import { Link } from "react-router-dom";
import Icon from "../../../components/Icon";
import Slider from "react-slick";
import WinnersLeaderboard from "../../Race/LeaderBoard/WinnersLeaderboard";
import Shade from "../../../images/stakeshade.png";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import Loader from "../../../components/Loader";
import {
  IoMdArrowDroprightCircle,
  IoMdArrowDropleftCircle,
} from "react-icons/io";
import RaceImage from "../../../images/RaceImg.png";
import RaceVideo from "../../../images/Race.mp4";
import CyberDogzAd from "../../../components/Advertisement/cyberdogz";
import RaceTable from "../../Race/Cash-Race";
import { WinnerList } from "./WinnerList";
import Spaceship from "../../../images/spaceship.png";
import { ImVolumeHigh, ImVolumeMute2 } from "react-icons/im";
const Race = (props) => {
  const [events, setEvents] = useState([]);
  const [liveEvents, setLiveEvents] = useState([]);
  const [replayEvent, setReplayEvent] = useState([]);
  const [leaderboard, setLeaderboard] = useState([]);
  const [alltimeLeaderboard, setAlltimeLeaderboard] = useState([]);
  const [eventsLoading, setEventsLoading] = useState(false);
  const [leaderboardLoading, setLeaderboardLoading] = useState(false);
  const [alltimeLeaderboardLoading, setAlltimeLeaderboardLoading] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectModal, setIsSelectModal] = useState(false);
  const [assets, setAssets] = useState([]);
  const [asset, setAsset] = useState({});
  const { account, active, activate, error, deactivate } = useWeb3React();
  const [page, setPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  const [leaderboardPage, setLeaderboardPage] = useState(1);
  const [leaderboardTotalDocs, setLeaderboardTotalDocs] = useState(0);
  const [alltimeLeaderboardPage, setAlltimeLeaderboardPage] = useState(1);
  const [alltimeLeaderboardTotalDocs, setAlltimeLeaderboardTotalDocs] =
    useState(0);
  const [nextRaceTime, setNextRaceTime] = useState(0);
  const [mute, setMute] = useState(false);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://player.vimeo.com/api/player.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  const nextRace = () => {
    let currentMinute = moment.utc().minutes();
    let defaultTime;
    if (currentMinute >= 0 && currentMinute <= 14) {
      defaultTime = 15 - currentMinute;
    } else if (currentMinute >= 15 && currentMinute <= 29) {
      defaultTime = 30 - currentMinute;
    } else if (currentMinute >= 30 && currentMinute <= 44) {
      defaultTime = 45 - currentMinute;
    } else if (currentMinute >= 45 && currentMinute <= 59) {
      defaultTime = 60 - currentMinute;
    }

    setNextRaceTime(moment().utc().add(defaultTime, "minutes").valueOf());
  };
  useEffect(() => {
    nextRace();
  }, []);

  const interval = useRef();

  useEffect(() => {
    interval.current = setInterval(() => {
      const currentMinute = moment.utc().minutes();
      const currentSecond = moment.utc().seconds();
      if (currentMinute % 15 == 0 && currentSecond == 0) {
        setNextRaceTime(moment().utc().add(15, "minutes").valueOf());
        // getRaceEvents();
      }
    }, 1000);
    return () => {
      clearInterval(interval.current);
      interval.current = null;
    };
  }, []);
  useEffect(() => {}, [nextRaceTime]);
  useEffect(() => {
    getRaceEvents();
  }, [page]);
  const getRaceEvents = async () => {
    setEventsLoading(true);
    let result;
    try {
      result = await GetRaceEvents(page, true);
    } catch (e) {
      console.log(e);
      setEventsLoading(false);
    }
    try {
      if (result.success) {
        setEventsLoading(false);
        setEvents(result.rooms.docs);
        setTotalDocs(result.rooms.totalDocs);
        let liveEvents = result.rooms.docs.filter((event) => event.registrable);
        liveEvents = liveEvents.slice(0, 3);
        setLiveEvents(liveEvents);
        let replayEvents = result.rooms.docs.filter(
          (event) => event.registrable
        );
        replayEvents = replayEvents.slice(0, 3);
        setReplayEvent(replayEvents);
      }
    } catch (e) {
      console.log(e);
      setEventsLoading(false);
    }
  };
  useEffect(() => {
    getRaceLeaderbord();
  }, [leaderboardPage]);
  useEffect(() => {
    getAlltimeLeaderbord();
  }, [alltimeLeaderboardPage]);
  const getRaceLeaderbord = async () => {
    setLeaderboardLoading(true);
    let result;
    try {
      result = await GetRaceLeaderbord(true, leaderboardPage);
    } catch (e) {
      console.log(e);
      setLeaderboardLoading(false);
    }
    try {
      if (result.success) {
        setLeaderboardLoading(false);
        setLeaderboard(result.leaderboard.docs);
        setLeaderboardTotalDocs(result.leaderboard.totalDocs);
      }
    } catch (e) {
      console.log(e);
      setLeaderboardLoading(false);
    }
  };
  const getAlltimeLeaderbord = async () => {
    setAlltimeLeaderboardLoading(true);
    let result;
    try {
      result = await GetRaceLeaderbord(false, alltimeLeaderboardPage);
    } catch (e) {
      console.log(e);
      setAlltimeLeaderboardLoading(false);
    }
    try {
      if (result.success) {
        setAlltimeLeaderboardLoading(false);
        setAlltimeLeaderboard(result.leaderboard.docs);
        setAlltimeLeaderboardTotalDocs(result.leaderboard.totalDocs);
      }
    } catch (e) {
      console.log(e);
      setAlltimeLeaderboardLoading(false);
    }
  };
  const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
    <button {...props}>{children}</button>
  );
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    infinite: true,
    nextArrow: (
      <SlickArrow>
        {/* <img src={Right} name="arrow-next" height="18px" /> */}
        <IoMdArrowDroprightCircle className={styles.arrow} />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        {/* <img src={Left} name="arrow-prev" height="18px" /> */}
        <IoMdArrowDropleftCircle className={styles.arrow} />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 1179,
        settings: {
          slidesToShow: 2,
          autoplay: true,
          infinite: true,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
          autoplay: true,
          infinite: true,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          autoplay: true,
          infinite: true,
        },
      },
    ],
  };
  const dateFunction = (date, time) => {
    let formatDate = moment
      .utc(20 + date + " " + time.replaceAll("-", ":"))
      .format();
    return moment(formatDate).fromNow();
  };
  console.log(mute);
  return (
    <div className={styles.raceContainer}>
      <div className={styles.raceBody}>
        <Row gutter={{ md: 8, lg: 16 }}>
          <Col xs={24} sm={24} md={24} lg={16}>
            {liveEvents.length ? (
              <div className={styles.game}>
                {/* <iframe
                  className={styles.gamewindow}
                  src={RACE_URL + liveEvents[0]?.gameId}
                  allowFullScreen
                ></iframe> */}
                <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                  <iframe
                    src={
                      "https://player.vimeo.com/video/752462244?h=9d582e0f34&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;controls=1"
                    }
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture;"
                    allowFullScreen
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                    title="CYBERDOGZ Racing Intro"
                  ></iframe>
                  {/* <div className={styles.volume}>
                    {mute ? (
                      <ImVolumeMute2
                        className={styles.mute}
                        onClick={() => {
                          setMute(false);
                        }}
                      />
                    ) : (
                      <ImVolumeHigh
                        className={styles.mute}
                        onClick={() => {
                          setMute(true);
                        }}
                      />
                    )}
                  </div> */}
                </div>
                {/* <video
                  autoPlay={true}
                  muted
                  loop={true}
                  src={RaceVideo}
                  type="video/mp4"
                  controls={false}
                  controlsList="nodownload"
                  poster={RaceImage}
                  className={styles.raceImg}
                /> */}
                {/* <img src={RaceImage} className={styles.raceImg} /> */}
                {/* <div className={styles.liveRaceDetails}>
                  <div className={styles.eventDetails}>
                    <Row style={{ marginBottom: "3px" }}>
                      <Col lg={6} md={6} sm={10} xs={10}>
                        <div className={styles.yield}>
                          {liveEvents[0]?.name} :
                        </div>
                      </Col>
                      <Col lg={8} md={8} sm={12} xs={12}>
                        <div className={styles.yield}>
                          #{liveEvents[0]?.roomNumber}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} md={6} sm={10} xs={10}>
                        <div className={styles.blueText}>CYBERDOGZ :</div>
                      </Col>
                      <Col lg={8} md={8} sm={12} xs={12}>
                        <div className={styles.field}>
                          {liveEvents[0]?.registeredDogs.length}/10
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} md={6} sm={10} xs={10}>
                        <div className={styles.blueText}>Bet Amount :</div>
                      </Col>
                      <Col lg={8} md={8} sm={12} xs={12}>
                        <div className={styles.field}>
                          {liveEvents[0]?.betAmount ? (
                            <>{liveEvents[0]?.betAmount} $KIBBLEZ</>
                          ) : (
                            <>Free entry</>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div> */}
                <div className={styles.shadow}></div>
              </div>
            ) : null}
          </Col>
          <Col xs={24} sm={24} md={24} lg={8}>
            {/* <div className={styles.Spaceship}>
              <img src={Spaceship} className={styles.spaceshipBg} />
              <div className={styles.spaceshipContent}>
                <div>
                  <div className={styles.blueShade}>
                    3D Spaceship Battle is{" "}
                  </div>
                  <center className={styles.orangeShade}>LIVE NOW</center>
                  <br />
                  <Link to="/spaceshipGame" className={styles.link}>
                    Explore
                  </Link>
                </div>
              </div>
            </div> */}

            <div className={styles.ads}>
              <CyberDogzAd />
            </div>
            {/* <div className={styles.slider}>
              <p className={styles.liveHead}>
                <span className={styles.yield}> Recent</span> Matches
              </p>
              <Slider className="race-slider" {...settings}>
                {events.slice(1, 6).map((x, index) => (
                  <Link
                    to={"/race/replay/" + x.gameId}
                    className={cn(styles.link)}
                    key={index}
                    // onClick={() => setActiveIndex(index)}
                  >
                    {x.name} #{x.roomNumber}
                  </Link>
                ))}
              </Slider>
            </div> */}
            {/* <img src={Shade} className={styles.shade} /> */}
            <WinnerList
              leaderboardLoading={leaderboardLoading}
              leaderboard={leaderboard}
              dateFunction={dateFunction}
            />
          </Col>
        </Row>
        <div className={styles.bigad}>
              <CyberDogzAd />
            </div>
        <RaceTable
          events={events}
          setPage={(page) => {
            setPage(page);
          }}
          totalDocs={totalDocs}
          page={page}
          eventsLoading={eventsLoading}
          isLoading={isLoading}
          assets={assets}
          refreshRaceEvents={getRaceEvents}
        />
      </div>
    </div>
  );
};
export default Race;
