import { Col, Row } from "antd";
import React from "react";
import PrimaryButton from "../../../components/PrimaryButton";
import styles from "./land.module.sass";
import Land from "../../../images/landNew.png";
import TransparentButton from "../../../components/TransparentButton";
import { useNavigate } from "react-router-dom";
const LandOwner = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.land}>
      <Row className={styles.landFlex}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className={styles.container}>
            <div className={styles.heading}>Be a Big Shot. Own a Stadium. </div>
            <div className={styles.text}>
              To own CYBERDOGZ Stadium you require a minimum of 50,000 parcels
              of land on Planet Andromeda. Don’t know where to start? Join
              CYBERDOGZ Discord.
            </div>
            <div className={styles.btnflex}>
              <PrimaryButton
                label="Sold Out"
                className={styles.landBtn}
                onClick={() => {
                  navigate("/detail/61e5377f7ccabd0e6cf6fde2");
                }}
              />
              <TransparentButton
                label="Discover Map"
                className={styles.mapBtn}
                onClick={() => {
                  window.open(
                    "https://drive.google.com/file/d/1jyW9B0NsyYda1wvTLxvkGCvoscDuDdi5/view",
                    "_blank"
                  );
                }}
              />
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <img src={Land} className={styles.landImg} alt="img" />
        </Col>
      </Row>
    </div>
  );
};

export default LandOwner;
