import React from "react";
import styles from "./news.module.sass";
import image1 from "../../../images/spaceshipCrate.png";
import image2 from "../../../images/capsule.jpg";
import image3 from "../../../images/spaceship.png";
import Right from "../../../images/arrownew.png";
import Slider from "react-slick";
import TransparentButton from "../../../components/TransparentButton";
import Icon from "../../../components/Icon";
import { HiOutlineArrowSmLeft, HiOutlineArrowSmRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
const assets = [
  {
    image: image1,
    name: "Introducing Spaceship Crate Mint",
    url: "/detail/626f87b7d87315dcb8aec78e",
    target: "_self",
    description:
      "Accomplish your mission aboard a CYBERDOGZ Spaceship. Reveal your craft and conquer the stars in this Mach 5 CYBERDOGZ Genesis Spaceships. ",
  },
  {
    image: image2,
    name: "New CyberDog Up for Grabs",
    url: "/detail/626f87b7d87315dcb8aec78e",
    target: "_self",
    description:
      "It’s finally time to thaw your female landers, I’m sure female lander thaw has been a long-awaited event. This blog post gives you a step by step process on how to thaw your female lander.",
  },
  {
    image: image3,
    name: "3D space Live Now",
    url: "https://cyberdogz-dev.itch.io/cyberdogz",
    target: "_black",
    description:
      "We have finally launched our official CYBERDOGZ BATTLESHIP GAME, BASIC GAMEPLAY The goal in the game is to reach the highest wave as possible as you can while surviving",
  },
  {
    image: image1,
    name: "Introducing Spaceship Crate Mint",
    url: "/detail/626f87b7d87315dcb8aec78e",
    target: "_self",
    description:
      "Accomplish your mission aboard a CYBERDOGZ Spaceship. Reveal your craft and conquer the stars in this Mach 5 CYBERDOGZ Genesis Spaceships. ",
  },
  {
    image: image2,
    name: "New CyberDog Up for Grabs",
    url: "/detail/626f87b7d87315dcb8aec78e",
    target: "_self",
    description:
      "It’s finally time to thaw your female landers, I’m sure female lander thaw has been a long-awaited event. This blog post gives you a step by step process on how to thaw your female lander.",
  },
  {
    image: image3,
    name: "3D Space Battle is Live Now",
    url: "/spaceshipGame",
    target: "_self",
    description:
      "We have finally launched our official CYBERDOGZ BATTLESHIP GAME, BASIC GAMEPLAY The goal in the game is to reach the highest wave as possible as you can while surviving",
  },
];
const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const NewsUpdates = () => {
  const navigate = useNavigate();
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    infinite: true,
    nextArrow: (
      <SlickArrow>
        <div className={styles.nextArrow}>
          <HiOutlineArrowSmRight name="arrow-next" size="30" />
        </div>
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <div className={styles.prevArrow}>
          <HiOutlineArrowSmLeft name="arrow-prev" size="30" />
        </div>
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 1179,
        settings: {
          slidesToShow: 2,
          autoplay: true,
          infinite: true,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
          autoplay: true,
          infinite: true,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          autoplay: true,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div className={styles.NewsUpdates}>
      <div className={styles.heading}>News and Updates</div>
      {/* <TransparentButton label="Load More" className={styles.loadMore} /> */}
      <Slider className="news-slider" {...settings}>
        {assets.map((x, index) => (
          <div className={styles.container} key={index}>
            <img src={x.image} alt="news" />
            <div className={styles.content}>
              <div className={styles.title}>{x.name}</div>
              <div className={styles.text}>{x.description}</div>
              <a href={x.url} target={x.target}>
                <TransparentButton
                  label="Read More"
                  className={styles.readMore}
                // onClick={() => navigate(x.url)}
                />
              </a>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default NewsUpdates;
