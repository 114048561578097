import React from "react";
import styles from "./Terms.module.sass";
import cn from "classnames";

const Terms = () => {
  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <h3 className={cn("h3", styles.title)}>TERMS OF USE</h3>
        <div className={styles.privacyBorder}>
          <div className={styles.store}>AGREEMENT TO TERMS</div>
          <div className={styles.grop}>
            <p>
              These Terms of Use constitute a legally binding agreement made
              between you, whether personally or on behalf of an entity (“you”)
              and CYBERDOGZ Decentralized Autonomous Organization (DAO),
              Affiliates and Community, doing business as CYBERDOGZ
              ("CYBERDOGZ", “we”, “us”, or “our”), concerning your access to and
              use of
            </p>
            <p>
              The
              <a href="https://www.cyberdogz.io"> https://www.cyberdogz.io</a>{" "}
              website as well as any other media form, media channel, mobile
              website or mobile application related, linked, or otherwise
              connected thereto (collectively, the “Site”). The Site provides
              an online marketplace for the following goods, products, and/or
              services: Non Fungible Tokens (NFTs) (the “Marketplace
              Offerings”). In order to help make the Site a secure environment
              for the purchase and sale of Marketplace Offerings, all users are
              required to accept and comply with these Terms of Use. You agree
              that by accessing the Site and/or the Marketplace Offerings, you
              have read, understood, and agree to be bound by all of these Terms
              of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN
              YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND/OR THE
              MARKETPLACE OFFERINGS AND YOU MUST DISCONTINUE USE IMMEDIATELY.
            </p>
            <br />
            <p>
              Supplemental terms and conditions or documents that may be posted
              on the Site from time to time are hereby expressly incorporated
              herein by reference. We reserve the right, in our sole discretion,
              to make changes or modifications to these Terms of Use from time
              to time. We will alert you about any changes by updating the “Last
              updated” date of these Terms of Use, and you waive any right to
              receive specific notice of each such change. Please ensure that
              you check the applicable Terms every time you use our Site so that
              you understand which Terms apply. You will be subject to, and will
              be deemed to have been made aware of and to have accepted, the
              changes in any revised Terms of Use by your continued use of the
              Site after the date such revised Terms of Use are posted.{" "}
            </p>
            <p>
              The information provided on the Site is not intended for
              distribution to or use by any person or entity in any jurisdiction
              or country where such distribution or use would be contrary to law
              or regulation or which would subject us to any registration
              requirement within such jurisdiction or country. Accordingly,
              those persons who choose to access the Site from other locations
              do so on their own initiative and are solely responsible for
              compliance with local laws, if and to the extent local laws are
              applicable.
            </p>
            <br />
            <p>
              Please note this website uses smart wallets, known as Web3, or
              Metamask, for login. You acknowledge by using our services that if
              you forget those recovery seeds your account and its contents are
              lost forever. All work, NFTs, products and services provided by
              the CYBERDOGZ team, community and affiliates is provided “As Is”
              and we make no other warranties, express or implied, and hereby
              disclaim all implied warranties, including any warranty of
              merchantability and warranty of fitness for a particular purpose.
              Use of the site is subject to certain risks, including but not
              limited to the following: virtual currency transactions may take
              time and be received much slower than expected (and may be
              reversed), value of virtual currency may fluctuate and the value
              of virtual currency may disappear, there is no assurance that
              virtual currency will continue to operate, transactions are
              irreversible and if you send it to the wrong address it will be
              lost forever, fraudulent or accidental transactions are not
              recoverable, virtual currency is not legal tender, regulatory and
              legislative changes and actions at a regional, international level
              may impact their use, virtual currency and the network they
              operate in may cease to exist and not work as a payment method in
              the case of a cyber attack.
            </p>
            <p>
              The Site is intended for users who are at least 18 years old.
              Persons under the age of 18 are not permitted to use or register
              for the Site or use the Marketplace Offerings.
            </p>
          </div>
          <div className={styles.store}>INTELLECTUAL PROPERTY RIGHTS</div>
          <div className={styles.grop}>
            <p>
              Unless otherwise indicated, the Site and the Marketplace Offerings
              are our proprietary property and all source code, databases,
              functionality, software, website designs, audio, video, text,
              photographs, and graphics on the Site (collectively, the
              “Content”) and the trademarks, service marks, and logos contained
              therein (the “Marks”) are owned or controlled by us or licensed to
              us, and are protected by copyright and trademark laws and various
              other intellectual property rights and unfair competition laws of
              the United States, international copyright laws, and international
              conventions. The Content and the Marks are provided on the Site
              “AS IS” for your information and personal use only. Except as
              expressly provided in these Terms of Use, no part of the Site or
              the Marketplace Offerings and no Content or Marks may be copied,
              reproduced, aggregated, republished, uploaded, posted, publicly
              displayed, encoded, translated, transmitted, distributed, sold,
              licensed, or otherwise exploited for any commercial purpose
              whatsoever, without our express prior written permission.
            </p>
            <p>
              Provided that you are eligible to use the Site, you are granted a
              limited license to access and use the Site and to download or
              print a copy of any portion of the Content to which you have
              properly gained access solely for your personal, non-commercial
              use. We reserve all rights not expressly granted to you in and to
              the Site, the Content and the Marks.
            </p>
          </div>
          <div className={styles.store}>USER REPRESENTATIONS</div>
          <div className={styles.grop}>
            <p>
              By using the Site or the Marketplace Offerings, you represent and
              warrant that:(1) all registration information you submit will be
              true, accurate, current, and complete; (2) you will maintain the
              accuracy of such information and promptly update such registration
              information as necessary; (3) you have the legal capacity and you
              agree to comply with these Terms of Use; (4) you are not a minor
              in the jurisdiction in which you reside; (5) you will not access
              the Site or the Marketplace Offerings through automated or
              non-human means, whether through a bot, script or otherwise; (6)
              you will not use the Site for any illegal or unauthorized purpose;
              and (7) your use of the Site or the Marketplace Offerings will not
              violate any applicable law or regulation.
            </p>

            <p>
              If you provide any information that is untrue, inaccurate, not
              current, or incomplete, we have the right to suspend or terminate
              your account and refuse any and all current or future use of the
              Site (or any portion thereof).
            </p>
            <p>
              You may not use the Site or the Marketplace Offerings for any
              illegal or unauthorized purpose nor may you, in the use of
              Marketplace Offerings, violate any laws. Among unauthorized
              Marketplace Offerings are the following: intoxicants of any sort;
              illegal drugs or other illegal products; alcoholic beverages;
              games of chance; and pornography or graphic adult content, images,
              or other adult products. Postings of any unauthorized products or
              content may result in immediate termination of your account and a
              lifetime ban from use of the Site.
            </p>
            <p>
              We are a service provider and make no representations as to the
              safety, effectiveness, adequacy, accuracy, availability, prices,
              ratings, reviews, or legality of any of the information contained
              on the Site or the Marketplace Offerings displayed or offered
              through the Site. You understand and agree that the content of the
              Site does not contain or constitute representations to be
              reasonably relied upon, and you agree to hold us harmless from any
              errors, omissions, or misrepresentations contained within the
              Site’s content. We do not endorse or recommend any Marketplace
              Offerings and the Site is provided for informational and
              advertising purposes only.
            </p>
          </div>
          <div className={styles.store}>USER REGISTRATION</div>
          <div className={styles.grop}>
            <p>
              You may be required to register with the Site in order to access
              the Marketplace Offerings. You agree to keep your password
              confidential and will be responsible for all use of your account
              and password. We reserve the right to remove, reclaim, or change a
              username you select if we determine, in our sole discretion, that
              such username is inappropriate, obscene, or otherwise
              objectionable.
            </p>
          </div>
          <div className={styles.store}>MARKETPLACE OFFERINGS</div>
          <div className={styles.grop}>
            <p>
              All Marketplace Offerings are subject to availability, and we
              cannot guarantee that Marketplace Offerings will be in stock.
              Certain Marketplace Offerings may be available exclusively online
              through the Site. Such Marketplace Offerings may have limited
              quantities and are subject to return or exchange only according to
              our Return Policy.
            </p>
            <p>
              We reserve the right to limit the quantities of the Marketplace
              Offerings offered or available on the Site. All descriptions or
              pricing of the Marketplace Offerings are subject to change at any
              time without notice, at our sole discretion. We reserve the right
              to discontinue any Marketplace Offerings at any time for any
              reason. We do not warrant that the quality of any of the
              Marketplace Offerings purchased by you will meet your expectations
              or that any errors in the Site will be corrected.
            </p>
          </div>
          <div className={styles.store}>PURCHASES AND PAYMENT</div>
          <div className={styles.grop}>
            <p>We accept the following forms of payment: </p>
            <br />
            <p>
              You agree to provide current, complete, and accurate purchase and
              account information for all purchases of the Marketplace Offerings
              made via the Site. You further agree to promptly update account
              and payment information, including email address, payment method,
              and payment card expiration date, so that we can complete your
              transactions and contact you as needed. Sales tax will be added to
              the price of purchases as deemed required by us. We may change
              prices at any time. All payments shall be in Ethereum.{" "}
            </p>
            <p>
              You agree to pay all charges at the prices then in effect for your
              purchases and any applicable shipping fees, and you authorize us
              to charge your chosen payment provider for any such amounts upon
              placing your order. We reserve the right to correct any errors or
              mistakes in pricing, even if we have already requested or received
              payment.
            </p>
            <p>
              We reserve the right to refuse any order placed through the Site.
              We may, in our sole discretion, limit or cancel quantities
              purchased per person, per household, or per order. These
              restrictions may include orders placed by or under the same
              customer account, the same payment method, and/or orders that use
              the same billing or shipping address. We reserve the right to
              limit or prohibit orders that, in our sole judgment, appear to be
              placed by dealers, resellers, or distributors.
            </p>
          </div>

          <div className={styles.store}>REFUNDS POLICY</div>
          <div className={styles.grop}>
            <p>All sales are final and no refund will be issued.</p>
          </div>
          <div className={styles.store}>PROHIBITED ACTIVITIES</div>
          <div className={styles.grop}>
            <p>
              You may not access or use the Site for any purpose other than that
              for which we make the Site available. The Site may not be used in
              connection with any commercial endeavors except those that are
              specifically endorsed or approved by us.
            </p>
          </div>

          <div className={styles.store}>
            As a user of the Site, you agree not to:
          </div>
          <div className={styles.grop}>
            <p>
              1. Systematically retrieve data or other content from the Site to
              create or compile, directly or indirectly, a collection,
              compilation, database, or directory without written permission
              from us.
              <br />
              2. Trick, defraud, or mislead us and other users, especially in
              any attempt to learn sensitive account information such as user
              passwords.
              <br />
              3. Circumvent, disable, or otherwise interfere with
              security-related features of the Site, including features that
              prevent or restrict the use or copying of any Content or enforce
              limitations on the use of the Site and/or the Content contained
              therein.
              <br />
              4. Disparage, tarnish, or otherwise harm, in our opinion, us
              and/or the Site.
              <br />
              5. Use any information obtained from the Site in order to harass,
              abuse, or harm another person.
              <br />
              6. Make improper use of our support services or submit false
              reports of abuse or misconduct.
              <br />
              7. Use the Site in a manner inconsistent with any applicable laws
              or regulations.
              <br />
              8. Engage in unauthorized framing of or linking to the Site.
              <br />
              9. Upload or transmit (or attempt to upload or to transmit)
              viruses, Trojan horses, or other material, including excessive use
              of capital letters and spamming (continuous posting of repetitive
              text), that interferes with any party’s uninterrupted use and
              enjoyment of the Site or modifies, impairs, disrupts, alters, or
              interferes with the use, features, functions, operation, or
              maintenance of the Marketplace Offerings.
              <br />
              10. Engage in any automated use of the system, such as using
              scripts to send comments or messages, or using any data mining,
              robots, or similar data gathering and extraction tools.
              <br />
              11. Delete the copyright or other proprietary rights notice from
              any Content.
              <br />
              12. Attempt to impersonate another user or person or use the
              username of another user.
              <br />
              13. Upload or transmit (or attempt to upload or to transmit) any
              material that acts as a passive or active information collection
              or transmission mechanism, including without limitation, clear
              graphics interchange formats (“gifs”), 1×1 pixels, web bugs,
              cookies, or other similar devices (sometimes referred to as
              “spyware” or “passive collection mechanisms” or “pcms”).
              <br />
              14. Interfere with, disrupt, or create an undue burden on the Site
              or the networks or services connected to the Site.
              <br />
              15. Harass, annoy, intimidate, or threaten any of our employees or
              agents engaged in providing any portion of the Marketplace
              Offerings to you.
              <br />
              16. Attempt to bypass any measures of the Site designed to prevent
              or restrict access to the Site, or any portion of the Site.
              <br />
              17. Copy or adapt the Site’s software, including but not limited
              to Flash, PHP, HTML, JavaScript, or other code.
              <br />
              18. Except as permitted by applicable law, decipher, decompile,
              disassemble, or reverse engineer any of the software comprising or
              in any way making up a part of the Site.
              <br />
              19. Except as may be the result of standard search engine or
              Internet browser usage, use, launch, develop, or distribute any
              automated system, including without limitation, any spider, robot,
              cheat utility, scraper, or offline reader that accesses the Site,
              or using or launching any unauthorized script or other software.
              <br />
              20. Use a buying agent or purchasing agent to make purchases on
              the Site.
              <br />
              21. Make any unauthorized use of the Marketplace Offerings,
              including collecting usernames and/or email addresses of users by
              electronic or other means for the purpose of sending unsolicited
              email, or creating user accounts by automated means or under false
              pretenses.
              <br />
              22. Use the Marketplace Offerings as part of any effort to compete
              with us or otherwise use the Site and/or the Content for any
              revenue-generating endeavor or commercial enterprise.
              <br />
              23. Use the Site to advertise or offer to sell goods and services.
              <br />
              24. Reverse engineer
              <br />
              25. Spam
              <br />
              26. Scrape
              <br />
              27. Download images
              <br />
              28. Copy media
              <br />
            </p>
          </div>
          <div className={styles.store}>USER GENERATED CONTRIBUTIONS</div>
          <div className={styles.grop}>
            <p>
              The Site does not offer users to submit or post content. We may
              provide you with the opportunity to create, submit, post, display,
              transmit, perform, publish, distribute, or broadcast content and
              materials to us or on the Site, including but not limited to text,
              writings, video, audio, photographs, graphics, comments,
              suggestions, or personal information or other material
              (collectively, "Contributions"). Contributions may be viewable by
              other users of the Site and through third-party websites. As such,
              any Contributions you transmit may be treated in accordance with
              the Site Privacy Policy. When you create or make available any
              Contributions, you thereby represent and warrant that:
            </p>
            <p>
              1. The creation, distribution, transmission, public display, or
              performance, and the accessing, downloading, or copying of your
              Contributions do not and will not infringe the proprietary rights,
              including but not limited to the copyright, patent, trademark,
              trade secret, or moral rights of any third party.
              <br />
              2. You are the creator and owner of or have the necessary
              licenses, rights, consents, releases, and permissions to use and
              to authorize us, the Site, and other users of the Site to use your
              Contributions in any manner contemplated by the Site and these
              Terms of Use.
              <br />
              3. You have the written consent, release, and/or permission of
              each and every identifiable individual person in your
              Contributions to use the name or likeness of each and every such
              identifiable individual person to enable inclusion and use of your
              Contributions in any manner contemplated by the Site and these
              Terms of Use.
              <br />
              4. Your Contributions are not false, inaccurate, or misleading.
              <br />
              5. Your Contributions are not unsolicited or unauthorized
              advertising, promotional materials, pyramid schemes, chain
              letters, spam, mass mailings, or other forms of solicitation.
              <br />
              6. Your Contributions are not obscene, lewd, lascivious, filthy,
              violent, harassing, libelous, slanderous, or otherwise
              objectionable (as determined by us).
              <br />
              7. Your Contributions do not ridicule, mock, disparage,
              intimidate, or abuse anyone.
              <br />
              8. Your Contributions are not used to harass or threaten (in the
              legal sense of those terms) any other person and to promote
              violence against a specific person or class of people.
              <br />
              9. Your Contributions do not violate any applicable law,
              regulation, or rule.
              <br />
              10. Your Contributions do not violate the privacy or publicity
              rights of any third party.
              <br />
              11. Your Contributions do not violate any applicable law
              concerning child pornography, or otherwise intended to protect the
              health or well-being of minors.
              <br />
              12. Your Contributions do not include any offensive comments that
              are connected to race, national origin, gender, sexual preference,
              or physical handicap.
              <br />
              13. Your Contributions do not otherwise violate, or link to
              material that violates, any provision of these Terms of Use, or
              any applicable law or regulation.
              <br />
              <br />
              Any use of the Site or the Marketplace Offerings in violation of
              the foregoing violates these Terms of Use and may result in, among
              other things, termination or suspension of your rights to use the
              Site and the Marketplace Offerings.
            </p>
          </div>
          <div className={styles.store}>CONTRIBUTION LICENSE</div>
          <div className={styles.grop}>
            <p>
              You and the Site agree that we may access, store, process, and use
              any information and personal data that you provide following the
              terms of the Privacy Policy and your choices (including settings).
            </p>
            <p>
              By submitting suggestions or other feedback regarding the Site,
              you agree that we can use and share such feedback for any purpose
              without compensation to you.
            </p>
            <p>
              We do not assert any ownership over your Contributions. You retain
              full ownership of all of your Contributions and any intellectual
              property rights or other proprietary rights associated with your
              Contributions. We are not liable for any statements or
              representations in your Contributions provided by you in any area
              on the Site. You are solely responsible for your Contributions to
              the Site and you expressly agree to exonerate us from any and all
              responsibility and to refrain from any legal action against us
              regarding your Contributions.
            </p>
          </div>
          <div className={styles.store}>SUBMISSIONS</div>
          <div className={styles.grop}>
            <p>
              You acknowledge and agree that any questions, comments,
              suggestions, ideas, feedback, or other information regarding the
              Site or the Marketplace Offerings ("Submissions") provided by you
              to us are non-confidential and shall become our sole property. We
              shall own exclusive rights, including all intellectual property
              rights, and shall be entitled to the unrestricted use and
              dissemination of these Submissions for any lawful purpose,
              commercial or otherwise, without acknowledgment or compensation to
              you. You hereby waive all moral rights to any such Submissions,
              and you hereby warrant that any such Submissions are original with
              you or that you have the right to submit such Submissions. You
              agree there shall be no recourse against us for any alleged or
              actual infringement or misappropriation of any proprietary right
              in your Submissions.
            </p>
          </div>
          <div className={styles.store}>THIRD-PARTY WEBSITES AND CONTENT</div>
          <div className={styles.grop}>
            <p>
              The Site may contain (or you may be sent via the Site or the
              Marketplace Offerings) links to other websites ("Third-Party
              Websites") as well as articles, photographs, text, graphics,
              pictures, designs, music, sound, video, information, applications,
              software, and other content or items belonging to or originating
              from third parties ("Third-Party Content"). Such Third-Party
              Websites and Third-Party Content are not investigated, monitored,
              or checked for accuracy, appropriateness, or completeness by us,
              and we are not responsible for any Third Party Websites accessed
              through the Site or any Third-Party Content posted on, available
              through, or installed from the Site, including the content,
              accuracy, offensiveness, opinions, reliability, privacy practices,
              or other policies of or contained in the Third-Party Websites or
              the Third-Party Content. Inclusion of, linking to, or permitting
              the use or installation of any Third-Party Websites or any
              Third-Party Content does not imply approval or endorsement thereof
              by us. If you decide to leave the Site and access the Third-Party
              Websites or to use or install any Third-Party Content, you do so
              at your own risk, and you should be aware these Terms of Use no
              longer govern. You should review the applicable terms and
              policies, including privacy and data gathering practices, of any
              website to which you navigate from the Site or relating to any
              applications you use or install from the Site. Any purchases you
              make through Third-Party Websites will be through other websites
              and from other companies, and we take no responsibility whatsoever
              in relation to such purchases which are exclusively between you
              and the applicable third party. You agree and acknowledge that we
              do not endorse the products or services offered on Third-Party
              Websites and you shall hold us harmless from any harm caused by
              your purchase of such products or services. Additionally, you
              shall hold us harmless from any losses sustained by you or harm
              caused to you relating to or resulting in any way from any
              Third-Party Content or any contact with Third-Party Websites.
            </p>
          </div>

          <div className={styles.store}> SITE MANAGEMENT</div>
          <div className={styles.grop}>
            <p>
              We reserve the right, but not the obligation, to: (1) monitor the
              Site for violations of these Terms of Use; (2) take appropriate
              legal action against anyone who, in our sole discretion, violates
              the law or these Terms of Use, including without limitation,
              reporting such user to law enforcement authorities; (3) in our
              sole discretion and without limitation, refuse, restrict access
              to, limit the availability of, or disable (to the extent
              technologically feasible) any of your Contributions or any portion
              thereof; (4) in our sole discretion and without limitation,
              notice, or liability, to remove from the Site or otherwise disable
              all files and content that are excessive in size or are in any way
              burdensome to our systems; and (5) otherwise manage the Site in a
              manner designed to protect our rights and property and to
              facilitate the proper functioning of the Site and the Marketplace
              Offerings.
            </p>
          </div>

          <div className={styles.store}> PRIVACY POLICY</div>
          <div className={styles.grop}>
            <p>
              We care about data privacy and security. By using the Site or the
              Marketplace Offerings, you agree to be bound by our Privacy Policy
              posted on the Site, which is incorporated into these Terms of Use.
              Please be advised the Site and the Marketplace Offerings are
              hosted in the United States. If you access the Site or the
              Marketplace Offerings from any other region of the world with laws
              or other requirements governing personal data collection, use, or
              disclosure that differ from applicable laws in the United States,
              then through your continued use of the Site, you are transferring
              your data to the United States, and you agree to have your data
              transferred to and processed in the United States.
            </p>
          </div>
          <div className={styles.store}>TERM AND TERMINATION</div>
          <div className={styles.grop}>
            <p>
              These Terms of Use shall remain in full force and effect while you
              use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS
              OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND
              WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE
              AND THE MARKETPLACE OFFERINGS (INCLUDING BLOCKING CERTAIN IP
              ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
              INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
              WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY
              APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
              PARTICIPATION IN THE SITE AND THE MARKETPLACE OFFERINGS OR DELETE
              YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY
              TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
            </p>
            <p>
              If we terminate or suspend your account for any reason, you are
              prohibited from registering and creating a new account under your
              name, a fake or borrowed name, or the name of any third party,
              even if you may be acting on behalf of the third party. In
              addition to terminating or suspending your account, we reserve the
              right to take appropriate legal action, including without
              limitation pursuing civil, criminal, and injunctive redress.
            </p>
          </div>
          <div className={styles.store}> MODIFICATIONS AND INTERRUPTIONS</div>
          <div className={styles.grop}>
            <p>
              We reserve the right to change, modify, or remove the contents of
              the Site at any time or for any reason at our sole discretion
              without notice. However, we have no obligation to update any
              information on our Site. We also reserve the right to modify or
              discontinue all or part of the Marketplace Offerings without
              notice at any time. We will not be liable to you or any third
              party for any modification, price change, suspension, or
              discontinuance of the Site or the Marketplace Offerings.
            </p>
            <p>
              We cannot guarantee the Site and the Marketplace Offerings will be
              available at all times. We may experience hardware, software, or
              other problems or need to perform maintenance related to the Site,
              resulting in interruptions, delays, or errors. We reserve the
              right to change, revise, update, suspend, discontinue, or
              otherwise modify the Site or the Marketplace Offerings at any time
              or for any reason without notice to you. You agree that we have no
              liability whatsoever for any loss, damage, or inconvenience caused
              by your inability to access or use the Site or the Marketplace
              Offerings during any downtime or discontinuance of the Site or the
              Marketplace Offerings. Nothing in these Terms of Use will be
              construed to obligate us to maintain and support the Site or the
              Marketplace Offerings or to supply any corrections, updates, or
              releases in connection therewith.
            </p>
          </div>
          <div className={styles.store}> GOVERNING LAW</div>
          <div className={styles.grop}>
            <p>
              These terms shall be governed by and defined following the laws of
              India. CYBERDOGZ Decentralized Autonomous Organization (DAO),
              Affiliates and Community and yourself irrevocably consent that the
              courts of India shall have exclusive jurisdiction to resolve any
              dispute which may arise in connection with these terms.
            </p>
          </div>
          <div className={styles.store}>
            {" "}
            DISPUTE RESOLUTION
            <p>Informal Negotiations</p>
          </div>
          <div className={styles.grop}>
            <p>
              To expedite resolution and control the cost of any dispute,
              controversy, or claim related to these Terms of Use (each
              "Dispute" and collectively, the "Disputes") brought by either you
              or us (individually, a "Party" and collectively, the "Parties"),
              the Parties agree to first attempt to negotiate any Dispute
              (except those Disputes expressly provided below) informally for at
              least thirty (30) days before initiating arbitration. Such
              informal negotiations commence upon written notice from one Party
              to the other Party.
            </p>
          </div>
          {/* <div className={styles.store}> Binding Arbitration</div>
          <div className={styles.grop}>
            <p>
              Any dispute arising out of or in connection with this contract,
              including any question regarding its existence, validity, or
              termination, shall be referred to and finally resolved by the
              International Commercial Arbitration Court under the European
              Arbitration Chamber (Belgium, Brussels, Avenue Louise, 146)
              according to the Rules of this ICAC, which, as a result of
              referring to it, is considered as the part of this clause. The
              number of arbitrators shall be three (3). The seat, or legal
              place, of arbitration shall be Chennai, India. The language of the
              proceedings shall be Tamil. The governing law of the contract
              shall be the substantive law of India.
            </p>
          </div> */}
          <div className={styles.store}> Restrictions</div>
          <div className={styles.grop}>
            <p>
              The Parties agree that any arbitration shall be limited to the
              Dispute between the Parties individually. To the full extent
              permitted by law, (a) no arbitration shall be joined with any
              other proceeding; (b) there is no right or authority for any
              Dispute to be arbitrated on a class-action basis or to utilize
              class action procedures; and (c) there is no right or authority
              for any Dispute to be brought in a purported representative
              capacity on behalf of the general public or any other persons.
            </p>
          </div>
          <div className={styles.store}>
            {" "}
            Exceptions to Informal Negotiations and Arbitration
          </div>
          <div className={styles.grop}>
            <p>
              The Parties agree that the following Disputes are not subject to
              the above provisions concerning informal negotiations and binding
              arbitration: (a) any Disputes seeking to enforce or protect, or
              concerning the validity of, any of the intellectual property
              rights of a Party; (b) any Dispute related to, or arising from,
              allegations of theft, piracy, invasion of privacy, or unauthorized
              use; and (c) any claim for injunctive relief. If this provision is
              found to be illegal or unenforceable, then neither Party will
              elect to arbitrate any Dispute falling within that portion of this
              provision found to be illegal or unenforceable and such Dispute
              shall be decided by a court of competent jurisdiction within the
              courts listed for jurisdiction above, and the Parties agree to
              submit to the personal jurisdiction of that court.
            </p>
          </div>
          <div className={styles.store}> CORRECTIONS</div>
          <div className={styles.grop}>
            <p>
              There may be information on the Site that contains typographical
              errors, inaccuracies, or omissions that may relate to the
              Marketplace Offerings, including descriptions, pricing,
              availability, and various other information. We reserve the right
              to correct any errors, inaccuracies, or omissions and to change or
              update the information on the Site at any time, without prior
              notice.
            </p>
          </div>
          <div className={styles.store}>DISCLAIMER</div>
          <div className={styles.grop}>
            <p>
              THE SITE AND THE MARKETPLACE OFFERINGS ARE PROVIDED ON AN AS-IS
              AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND
              OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT
              PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
              IN CONNECTION WITH THE SITE AND THE MARKETPLACE OFFERINGS AND YOUR
              USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES
              OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
              NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT
              THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT
              OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY
              OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
              CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF
              ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF
              THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE
              SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
              INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF
              TRANSMISSION TO OR FROM THE SITE OR THE MARKETPLACE OFFERINGS, (5)
              ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
              TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6)
              ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY
              LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
              CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
              SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
              RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY
              A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY
              WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
              ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
              RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
              THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE
              PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY
              ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE
              CAUTION WHERE APPROPRIATE.
            </p>
          </div>
          <div className={styles.store}>LIMITATIONS OF LIABILITY</div>
          <div className={styles.grop}>
            <p>
              IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
              LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
              CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
              DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR
              OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE OR THE MARKETPLACE
              OFFERINGS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
              DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
              HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND
              REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED
              TO THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO US OR $100.00
              USD. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW
              LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION
              OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE
              ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY
              HAVE ADDITIONAL RIGHTS.
            </p>
          </div>
          <div className={styles.store}>INDEMNIFICATION</div>
          <div className={styles.grop}>
            <p>
              You agree to defend, indemnify, and hold us harmless, including
              our subsidiaries, affiliates, and all of our respective officers,
              agents, partners, and employees, from and against any loss,
              damage, liability, claim, or demand, including reasonable
              attorneys’ fees and expenses, made by any third party due to or
              arising out of: (1) use of the Marketplace Offerings; (2) breach
              of these Terms of Use; (3) any breach of your representations and
              warranties set forth in these Terms of Use; (4) your violation of
              the rights of a third party, including but not limited to
              intellectual property rights; or (5) any overt harmful act toward
              any other user of the Site or the Marketplace Offerings with whom
              you connected via the Site. Notwithstanding the foregoing, we
              reserve the right, at your expense, to assume the exclusive
              defense and control of any matter for which you are required to
              indemnify us, and you agree to cooperate, at your expense, with
              our defense of such claims. We will use reasonable efforts to
              notify you of any such claim, action, or proceeding which is
              subject to this indemnification upon becoming aware of it.
            </p>
          </div>
          <div className={styles.store}>USER DATA</div>
          <div className={styles.grop}>
            <p>
              We will maintain certain data that you transmit to the Site for
              the purpose of managing the performance of the Marketplace
              Offerings, as well as data relating to your use of the Marketplace
              Offerings. Although we perform regular routine backups of data,
              you are solely responsible for all data that you transmit or that
              relates to any activity you have undertaken using the Marketplace
              Offerings. You agree that we shall have no liability to you for
              any loss or corruption of any such data, and you hereby waive any
              right of action against us arising from any such loss or
              corruption of such data.
            </p>
          </div>
          <div className={styles.store}>
            ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
          </div>
          <div className={styles.grop}>
            <p>
              Visiting the Site, sending us emails, and completing online forms
              constitute electronic communications. You consent to receive
              electronic communications, and you agree that all agreements,
              notices, disclosures, and other communications we provide to you
              electronically, via email and on the Site, satisfy any legal
              requirement that such communication be in writing. YOU HEREBY
              AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND
              OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES,
              AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA
              THE SITE. You hereby waive any rights or requirements under any
              statutes, regulations, rules, ordinances, or other laws in any
              jurisdiction which require an original signature or delivery or
              retention of non-electronic records, or to payments or the
              granting of credits by any means other than electronic means.{" "}
            </p>
          </div>
          <div className={styles.store}>CALIFORNIA USERS AND RESIDENTS</div>
          <div className={styles.grop}>
            <p>
              If any complaint with us is not satisfactorily resolved, you can
              contact the Complaint Assistance Unit of the Division of Consumer
              Services of the California Department of Consumer Affairs in
              writing at 1625 North Market Blvd., Suite N 112, Sacramento,
              California 95834 or by telephone at (800) 952-5210 or (916)
              445-1254.
            </p>
          </div>
          <div className={styles.store}>MISCELLANEOUS</div>
          <div className={styles.grop}>
            <p>
              These Terms of Use and any policies or operating rules posted by
              us on the Site or in respect to the Marketplace Offerings
              constitute the entire agreement and understanding between you and
              us. Our failure to exercise or enforce any right or provision of
              these Terms of Use shall not operate as a waiver of such right or
              provision. These Terms of Use operate to the fullest extent
              permissible by law. We may assign any or all of our rights and
              obligations to others at any time. We shall not be responsible or
              liable for any loss, damage, delay, or failure to act caused by
              any cause beyond our reasonable control. If any provision or part
              of a provision of these Terms of Use is determined to be unlawful,
              void, or unenforceable, that provision or part of the provision is
              deemed severable from these Terms of Use and does not affect the
              validity and enforceability of any remaining provisions. There is
              no joint venture, partnership, employment or agency relationship
              created between you and us as a result of these Terms of Use or
              use of the Marketplace Offerings. You agree that these Terms of
              Use will not be construed against us by virtue of having drafted
              them. You hereby waive any and all defenses you may have based on
              the electronic form of these Terms of Use and the lack of signing
              by the parties hereto to execute these Terms of Use.
            </p>
            <p>
              This document and CYBERDOGZ project is not a financial instrument
              and not an offer to buy, sell or earn any kind of asset, currency
              or token. Please note this is a community project and there is no
              implied warranties, guarantees or value whatsoever. Please be
              careful with your resources and use at your own risk (this
              includes breeding, playing, trading and voting). Please note plans
              and roadmap subject to change based on internal and external
              factors including community governance. Please be aware that if
              you use a wallet such as Metamask and lose the recovery keys we
              cannot be held responsible. Do not share these keys with anyone
              and be mindful about who you share your sensitive information or
              details with. CYBERDOGZ is intended for audiences 18+ years of
              age. CYBERDOGZ is not a “company” and is a community project that
              plans to be governed by a Decentralized Autonomous Organization
              (DAO). If a corporate entity is required for any purpose including
              holding trademarks and other organizational efficiencies CYBERDOGZ
              at its own discretion reserves the right to do so on any structure
              and conditions, without any notice, consideration or reservations
              to you. By participating in CYBERDOGZ you agree to all these
              Terms.
            </p>
          </div>
          <div className={styles.store}>CONTACT US</div>
          <div className={styles.grop}>
            <p>
              In order to resolve a complaint regarding the Site or the
              Marketplace Offerings or to receive further information regarding
              use of the Site or the Marketplace Offerings, please contact us
              at:{" "}
            </p>
            CYBERDOGZ Decentralized Autonomous Organization (DAO), <br />
            <a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=hello@CYBERDOGZ.io">
              {" "}
              hello@cyberdogz.io.
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
