import React, { useEffect, useState } from "react";
import Styles from "../Table.module.sass";
import { Row, Col } from "antd";
import HistoryShade from "../../../../images/historyshade.png";
import {
  GetKibblezHistory,
  GetUserAssets,
} from "../../../../services/ApiServices";
import cn from "classnames";
import moment from "moment";
import Loader from "../../../../components/Loader";
import Kibblez from "../../../../images/kibblez.png";
import Bonez from "../../../../images/bonez.png";
import CounDown from "../../../../components/CountDown";
import { minifyAddress } from "../../../../utils/helpers";
import { Link } from "react-router-dom";
import Pagination from "../../../../components/Pagenation";
import TableLoader from "../../../../components/TableLoader";

const history = [];
const navLinks = ["OWNERS", "SCHOLARS"];
const AllTimeLeaderboard = ({
  alltimeLeaderboard,
  alltimeLeaderboardLoading,
  alltimeLeaderboardPage,
  alltimeLeaderboardTotalDocs,
  setAlltimeLeaderboardPage,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const  dateFunction=(date, time) =>{
    let formatDate = moment
      .utc(20 + date + " " + time.replaceAll("-", ":"))
      .format();
    return moment(formatDate).fromNow();
  }
  const position=(state)=>{
    if(state=="first")
    return (<div className={Styles.position}>1<sup>st</sup></div> )
    if(state=="second")
    return (<div className={Styles.position}>2<sup>nd</sup></div> )
    if(state=="third")
    return (<div className={Styles.position}>3<sup>rd</sup></div> )
    if(state=="loss")
    return (<div className={Styles.pricefield}>Lost</div> )
  }

  return (
    <>
      <p className={Styles.title}>
        Recent Activities
      </p>

      {/* <div className={Styles.nav}>
        {navLinks.map((x, index) => (
          <button
            className={cn(Styles.link, {
              [Styles.active]: index === activeIndex,
            })}
            key={index}
            onClick={() => setActiveIndex(index)}
          >
            {x}
          </button>
        ))}
      </div> */}
      <div className={Styles.history_table}>
        <div className={Styles.rowdata}>
          <Row>
            <Col span={4}>
              <div className={Styles.seller}>Position</div>
            </Col>
            <Col span={5}>
              <div className={Styles.seller}>User Address</div>
            </Col>
            <Col span={5}>
              <div className={Styles.seller}>Timestamp</div>
            </Col>
            <Col span={6}>
              <div className={Styles.seller}>Dog Name</div>
            </Col>
            <Col span={4}>
              <div className={Styles.seller}>Amount</div>
            </Col>
          </Row>
        </div>
        {/* <img src={HistoryShade} className={Styles.history_shade} /> */}

        {alltimeLeaderboardLoading ? (
                  <TableLoader />

        ) : alltimeLeaderboard.length ? (
          <>
            {alltimeLeaderboard.map((l, index) => {
              return (
                <div className={Styles.rowdata} key={index}>
                  <Row>
                    <Col span={4}>
                      {position(l.winStatus)}
                    </Col>
                    <Col span={5}>
                      <div className={Styles.pricefield}>
                        {minifyAddress(l.userAddress, 6)}
                      </div>
                    </Col>

                    <Col span={5}>
                      <div className={Styles.pricefield}>
                      {dateFunction(l.date,l.time)}
                      </div>
                    </Col>
                    <Col span={6}>
                      <div className={Styles.pricefield}>
                        {l.userDogName} #{l.userDogId}
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className={Styles.pricefield}>
                      {l.winAmount?
                      <>
                      <img className={Styles.icon} src={Bonez} />
                        {l.winAmount}
                      </>:
                      <div className={Styles.pricefield}>
                        -
                      </div>
                      }
                      
                      </div>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </>
        ) : (
          <p className={Styles.empty}>No data available</p>
        )}
        <div>
          <Pagination
            onChange={(page) => {
              setAlltimeLeaderboardPage(page);
            }}
            total={alltimeLeaderboardTotalDocs}
            current={alltimeLeaderboardPage}
          />
        </div>
      </div>
    </>
  );
};
export default AllTimeLeaderboard;
