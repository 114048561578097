import React, { useEffect, useState } from "react";
import Styles from "../RecentActivities.module.sass";
import { Row, Col, Empty } from "antd";
import moment from "moment";
import Loader from "../../../../../components/Loader";
const Leaderboard = ({ leaderboard, loading }) => {
  function getOrdinalSuffix(number) {
    const suffixes = ["th", "st", "nd", "rd"];
    const lastDigit = number % 10;
    const secondLastDigit = Math.floor((number % 100) / 10);

    return secondLastDigit === 1 || lastDigit > 3
      ? suffixes[0]
      : suffixes[lastDigit];
  }

  function NumberListWithOrdinals({ numbers }) {
    return (
      <ul>
        {numbers.map((number, index) => (
          <li key={index}>
            {number}
            {getOrdinalSuffix(number)}
          </li>
        ))}
      </ul>
    );
  }
  return (
    <>
      <div className={Styles.history_table}>
        <div className={Styles.rowdata}>
          <Row>
            <Col span={4}>
              <div className={Styles.seller}>Rank</div>
            </Col>
            <Col span={12}>
              <div className={Styles.seller}>Name</div>
            </Col>
            <Col span={8}>
              <div className={Styles.seller}>Kills</div>
            </Col>
            {/* <Col span={8}>
              <div className={Styles.seller}>Time</div>
            </Col> */}
          </Row>
        </div>
        {/* <img src={HistoryShade} className={Styles.history_shade} /> */}
        {loading ? (
          <Loader className={Styles.loader} />
        ) : leaderboard.length ? (
          <>
            {leaderboard.map((data, index) => {
              return (
                <div className={Styles.rowdata} key={index}>
                  <Row>
                    <Col span={4}>
                      <div className={Styles.rank}>
                        {data.rank + 1} {getOrdinalSuffix(data.rank + 1)}
                      </div>
                    </Col>

                    {/* <Col span={8}>
                      <div className={Styles.status}>{data.playerId}</div>
                    </Col> */}
                    <Col span={12}>
                      <div className={Styles.status}>{data.playerName}</div>
                    </Col>
                    <Col span={8}>
                      <div className={Styles.pricefield}>
                        {parseInt(data.totalScore) / 100}
                      </div>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </>
        ) : (
          <p className={Styles.empty}>No data available</p>
        )}
      </div>
    </>
  );
};
export default Leaderboard;
