import React, { useEffect, useState } from "react";
import Styles from "../Table.module.sass";
import { Row, Col } from "antd";
import HistoryShade from "../../../../images/historyshade.png";
import {
  GetKibblezHistory,
  GetUserAssets,
} from "../../../../services/ApiServices";
import cn from "classnames";
import moment from "moment";
import Loader from "../../../../components/Loader";
import Kibblez from "../../../../images/kibblez.png";
import Bonez from "../../../../images/bonez.png";
import CounDown from "../../../../components/CountDown";
import { minifyAddress } from "../../../../utils/helpers";
import { Link } from "react-router-dom";
import Pagination from "../../../../components/Pagenation";
import TableLoader from "../../../../components/TableLoader";

const history = [];
const colors = [
  "#32DA00",
  "#00D6DA",
  "#DA00B0",
  "#DAB800",
  "#DA1900",
  "#3A00FC",
  "#E7FF2E",
  "#38FFBA",
  "#FC209F",
  "#FF555C",
];
const navLinks = ["OWNERS", "SCHOLARS"];
const AllTimeLeaderboard = ({
  alltimeLeaderboard,
  alltimeLeaderboardLoading,
  alltimeLeaderboardPage,
  alltimeLeaderboardTotalDocs,
  setAlltimeLeaderboardPage,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      <p className={Styles.title}>
        All time LeaderBoard
        {/* <span className={Styles.heading}>LeaderBoard</span> */}
      </p>

      {/* <div className={Styles.nav}>
        {navLinks.map((x, index) => (
          <button
            className={cn(Styles.link, {
              [Styles.active]: index === activeIndex,
            })}
            key={index}
            onClick={() => setActiveIndex(index)}
          >
            {x}
          </button>
        ))}
      </div> */}
      <div className={Styles.history_table}>
        <div className={Styles.rowdata}>
          <Row>
            <Col span={1}></Col>
            <Col span={4}>
              <div className={Styles.seller}>Name</div>
            </Col>
            <Col span={3}>
              <div className={Styles.seller}>Address</div>
            </Col>
            <Col span={3}>
              <div className={Styles.seller}>Matches</div>
            </Col>
            <Col span={3}>
              <div className={Styles.seller}>Points</div>
            </Col>
            <Col span={3}>
              <div className={Styles.seller}>Kills</div>
            </Col>
            <Col span={3}>
              <div className={Styles.seller}>Deaths</div>
            </Col>

            <Col span={4}>
              <div className={Styles.seller}>Time Spent</div>
            </Col>
          </Row>
        </div>
        {/* <img src={HistoryShade} className={Styles.history_shade} /> */}

        {alltimeLeaderboardLoading ? (
          <TableLoader />
        ) : alltimeLeaderboard.length ? (
          <>
            {alltimeLeaderboard.map((l, index) => {
              return (
                <div className={Styles.rowdata} key={index}>
                  <Row>
                    <Col span={1}>
                      <div
                        style={{
                          backgroundColor: colors[index % 10],
                          boxShadow: colors[index % 10] + " 0px 5px 15px",
                        }}
                        className={Styles.style}
                      ></div>
                    </Col>
                    <Col span={4} className={Styles.column}>
                      <div className={Styles.pricefield}>
                        {/* <img src={l.profileUrl} className={Styles.logo} /> */}
                        {l.name}
                      </div>
                    </Col>
                    <Col span={3} className={Styles.column}>
                      <div className={Styles.pricefield}>
                        {minifyAddress(l._id, 4)}
                      </div>
                    </Col>
                    <Col span={3} className={Styles.column}>
                      <div className={Styles.pricefield}>{l.totalMatches}</div>
                    </Col>
                    <Col span={3} className={Styles.column}>
                      <div className={Styles.pricefield}>{l.totalPoints}</div>
                    </Col>
                    <Col span={3} className={Styles.column}>
                      <div className={Styles.pricefield}>{l.totalKills}</div>
                    </Col>
                    <Col span={3} className={Styles.column}>
                      <div className={Styles.pricefield}>{l.totalDeaths}</div>
                    </Col>
                    <Col span={4} className={Styles.column}>
                      <div className={Styles.pricefield}>
                        {/* {l.totalTimeSpent} */}
                        {moment.utc(l.totalTimeSpent * 1000).hours()}
                        hrs&nbsp;
                        {moment.utc(l.totalTimeSpent * 1000).minutes()}
                        mins&nbsp;
                        {moment.utc(l.totalTimeSpent * 1000).seconds()}secs
                      </div>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </>
        ) : (
          <p className={Styles.empty}>No data available</p>
        )}
        {/* <div>
          <Pagination
            onChange={(page) => {
              setAlltimeLeaderboardPage(page);
            }}
            total={alltimeLeaderboardTotalDocs}
            current={alltimeLeaderboardPage}
          />
        </div> */}
      </div>
    </>
  );
};
export default AllTimeLeaderboard;
