import React, { useEffect, useRef, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import styles from "./Shooter.module.sass";
import Background from "../../../images/homebackground.jpg";
import Shooter from "../../../images/game.mp4";
import MobBackground from "../../../images/MobBg.jpg";
import Smoke from "../../../images/Smoke.png";
import Fire from "../../../images/fire.mp4";
import { useSnackbar } from "notistack";
import {
  Game3DShooterLeaderBoard,
  RegisterLottery,
} from "../../../services/ApiServices";
import { Spinner } from "react-bootstrap";
import PrimaryButton from "../../../components/PrimaryButton";
import { Col, Row, Tooltip } from "antd";
import Leaderboard from "./Leaderboard";
import LottieLoader from "react-lottie-loader";
import Play from "../../../json/play.json";
import Live from "../../../json/livestream.json";
import RecentActivities from "./RecentActivities";
import { minifyAddress } from "../../../utils/helpers";
import { RiFileCopyLine } from "react-icons/ri";
import { IoOpen, IoOpenOutline } from "react-icons/io5";
const ShooterGame = (props) => {
  return (
    <div>
      <div className={styles.LotteryContainer}>
        {/* <div class="html_embed_widget base_widget embed_wrapper" id="html_embed_2034999">
    <div data-height="720" style="width: 1280px; height: 720px" data-width="1280" class="game_frame game_loaded">
    <iframe id="game_drop" allow="autoplay; fullscreen *; geolocation; microphone; camera; midi; monetization; xr-spatial-tracking; gamepad; gyroscope; accelerometer; xr; cross-origin-isolated" src="https://v6p9d9t4.ssl.hwcdn.net/html/7845577/index.html" frameborder="0" allowfullscreen="true" scrolling="no" allowtransparency="true" webkitallowfullscreen="true" mozallowfullscreen="true" msallowfullscreen="true"></iframe>
    </div></div> */}
        {/* <img src={Background} className={styles.bg} /> */}
        <video
          autoPlay={true}
          muted
          loop={true}
          src={Shooter}
          type="video/mp4"
          controls={false}
          controlsList="nodownload"
          poster={Background}
          className={styles.bg}
        />
        {/* <img src={MobBackground} className={styles.mobBg} /> */}
        <div className={styles.live}>
          <LottieLoader animationData={Live} className={styles.liveanimation} />

        </div>

        <div className={styles.content}>
          <div className={styles.message}>Welcome to CYBERDOGZ</div>
          <div
            className={styles.playnow}
            onClick={() =>
              window.open("https://cyberdogz-dev.itch.io/cyberdogz", "_blank")
            }
          >
            <LottieLoader animationData={Play} className={styles.play} />
          </div>

          {/* <PrimaryButton
          label="Play now"
          className={styles.access}
          onClick={()=>window.open("https://cyberdogz-dev.itch.io/cyberdogz", "_blank")}
        /> */}
        </div>
      </div>
      <div className={styles.contract_address}>Solana Contract Address : <span>{minifyAddress("G4USAGAtyEfW6B5abRRNHnvn1x2Vhcxhfvdv2h3m24iW", 10)}
        <Tooltip title="Copied!" color="#414141" trigger="click">
          <RiFileCopyLine
            className={styles.icon}
            onClick={() => {
              navigator.clipboard.writeText("G4USAGAtyEfW6B5abRRNHnvn1x2Vhcxhfvdv2h3m24iW");
            }}
          />
        </Tooltip>
      </span>


      </div>
      <div className={styles.contract_address}>
        <a
          href="https://www.dextools.io/app/en/solana/pair-explorer/EMRGbY1VTBaxZLEMokNejJuQVVf56baoJQvS7wmfJAQr?t=1711342666836"
          target="_blank"
          rel="noreferrer"
        >
          DEXTools Link
          <IoOpenOutline
            className={styles.icon}
          />
        </a>
      </div>
      <br />
      <br />

      <div className={styles.tournament}>
        <Row>
          <Col xs={24} md={12} lg={12}>
            <div className={styles.message}>Most Kills</div>
            <div className={styles.label}>
              Monthly Leaderboard ends March 31, 2024 at 11:59 PM UTC. Most
              kills wins. $100 for 1st place, $50 for 2nd place and $25 for 3rd
              place. Paid in ETH
              <br />
            </div>
            <Leaderboard />
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className={styles.message}>How it works</div>
            <div className={styles.label}>
              Unleash the cosmic warfare in CYBERDOGZ Spaceship Duels! Dive into
              a zero-cost multiplayer arena, ascend as the galaxy's ace pilot,
              and seize stellar cash rewards. No purchase necessary. But with a
              Rare or Legendary CYBERDOGZ NFT, triple your treasures. Ready to
              embark? Hit 'Play Now' and command your destiny.
              <b>{" "}
                No purchase necessary. No NFT Required. NFT Rare and Legendary
                Rare CYBERDOGZ holders Receive 3X payouts.
              </b>
            </div>
            <div className={styles.message}>Recent Activities</div>
            <RecentActivities />
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default ShooterGame;
